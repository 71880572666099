import immutable from 'immutable'

const INITIAL_STATE = immutable.fromJS({})

const uiStateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case 'SIDEBAR_STATE':
        return state.set('sidebarState', action.show)
    case 'SET_VIEW_MODE':
        return state.set('viewMode', action.mode)
    case 'SET_ERROR_CODE':
        return state
            .set('hasError', action.hasError)
            .set('code', action.code)
            .set('page', window.location.pathname)
    case 'CLEAR_ERROR_CODE':
        return state
            .set('hasError', false)
            .set('code', null)
            .set('page', null)
    case 'SET_ACTIVE_USER_TOKEN':
        return state
            .set('userTokenActive', true)
    case 'KILL_STATE':
        return immutable.fromJS({})
    default:
        return state
    }
}

export default uiStateReducer
