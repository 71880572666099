import firebase from 'firebase/app'
import { upsertForm } from './form'
import { upsertFetching } from './fetching'
import { fireauth, actionCodeSettings } from '../firebase/firebase'
import { setActiveUserToken } from './ui'

import Cookies from 'universal-cookie'

import { fetchingStates, formStates } from '../constants/helper-states'

import {
    KILL_STATE, SAVE_AUTH, SAVE_ANONYMOUS_AUTH, REMOVE_AUTH, SAVE_AUTH_HANDLER, REMOVE_AUTH_HANDLER
} from '../constants/actions'

const killState = () => {
    return {
        type: KILL_STATE
    }
}

const killPartialState = (key) => {
    return {
        type: `KILL_PARTIAL_${key}`
    }
}

const saveAuth = (auth) => {
    return {
        type: SAVE_AUTH,
        auth
    }
}

const saveAnonymousAuth = (auth) => {
    return {
        type: SAVE_ANONYMOUS_AUTH,
        auth
    }
}

export const removeAuth = () => {
    return {
        type: REMOVE_AUTH
    }
}

const saveAuthHandlerConfig = (code, action, email) => {
    return {
        type: SAVE_AUTH_HANDLER,
        code,
        action,
        email
    }
}

const saveAuthHandlerError = () => {
    return {
        type: 'AUTH_HANDLER_ERROR'
    }
}

export const clearAuthCode = () => {
    return {
        type: REMOVE_AUTH_HANDLER
    }
}

export const connectToPusherWebsocket = () => {
    return fireauth.currentUser.getIdToken()
        .then(token => {
            return {
                Authorization: `Bearer ${token}`
            }
        }).catch(err => {
            console.log('unable to get authorization info')
        })
}

export const signInWithUserToken = (token) => {
    return dispatch => {
        const cookies = new Cookies()
        cookies.set('user-token-active', true, { path: '/' })
        dispatch(setActiveUserToken())
        // dispatch partial kill state
        const killKeys = [
            'ADMIN_ACCOUNTS', 'ADMIN_INVITATIONS', 'ADMIN_ROLES', 'ADMIN_USER', 'TOPIC_CALCULATIONS', 'PROFORMA_CALCULATIONS',
            'CLIENTS', 'CONTACTS', 'IMAGES', 'PROFORMA_TOPICS', 'PROFORMA_CALCULATIONS', 'PROJECTS', 'PROJECT_CALCULATIONS',
            'PROJECT_SCORECARDS', 'REPORTS', 'REPORT_CHARTS', 'REPORT_DATA', 'REPORT_INVITATIONS', 'REPORT_TEMPLATES', 'REPORT_VIEWS',
            'REPORT_TIMESTAMPS', 'REPORT_TOPIC_ITEM_DATA', 'SCENARIOS', 'SITES', 'SCENARIO_SCORECARDS', 'SCORECARD_OPTIONS',
            'SCORECARD_TEMPLATES', 'TOPIC_CALCULATIONS', 'TOPIC_DATA', 'TOPIC_ITEM_CALCULATIONS', 'TOPIC_DEFINITION_STEPS', 'PROFORMA_TOPIC_ITEMS'
        ]
        killKeys.forEach(key => {
            dispatch(killPartialState(key))
        })
        fireauth.signInWithCustomToken(token)
            .then(() => {
                // do nothing for now
            })
            .catch(err => {
                console.log('unable to switch auth token?')
                dispatch(killState())
            })
    }
}

export const handleFirebaseAuthCode = (code, action) => {
    return dispatch => {
        fireauth.verifyPasswordResetCode(code)
            .then(email => {
                dispatch(saveAuthHandlerConfig(code, action, email))
            })
            .catch(err => {
                dispatch(saveAuthHandlerError())
                console.log('got an error...', err)
            })
    }
}

export const anonymousSignIn = (fetchingId) => {
    return dispatch => {
        fireauth.signInAnonymously()
            .then(() => {
                dispatch(upsertFetching(fetchingId, fetchingStates.COMPLETE))
            })
            .catch(err => {
                console.log('error with anonymous')
            })
    }
}

export const registerWithInvitation = (model, password, invitationId, formId) => {
    return async dispatch => {
        const result = await model.acceptInvitation({ password, invitationId })
        if (typeof result === 'undefined' || result.hasError) {
            dispatch(upsertForm(formId, formStates.ERROR, `Error: ${result.error.message}`))
        } else {
            fireauth.signInWithEmailAndPassword(model.get('email'), password)
                .then(() => {
                    dispatch(upsertForm(formId, formStates.SUCCESS))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
                })
        }
    }
}

export const registerInvitationWithEmailAndPass = (email, password, formId) => {
    return dispatch => {
        fireauth.createUserWithEmailAndPassword(email, password)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                // TODO: do we want to handle specific response codes?
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const registerWithEmailAndPass = (email, password, formId) => {
    return dispatch => {
        fireauth.createUserWithEmailAndPassword(email, password)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                // TODO: do we want to handle specific response codes?
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const signInWithEmailAndPass = (email, password, formId) => {
    return dispatch => {
        fireauth.signInWithEmailAndPassword(email, password)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                console.log(err)
                let message = err.message
                if (err.code === 'auth/user-not-found') {
                    message = 'No account found with that email address.'
                }
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${message}`))
            })
    }
}

export const generatePasswordResetLink = (email, formId) => {
    return dispatch => {
        fireauth.sendPasswordResetEmail(email, actionCodeSettings)
            .then(() => {
                dispatch(upsertForm(formId, formStates.SUCCESS))
            })
            .catch(err => {
                console.log(err)
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const resetPasswordWithCode = (code, email, password, formId) => {
    return dispatch => {
        return fireauth.confirmPasswordReset(code, password)
            .then((response) => {
                fireauth.signInWithEmailAndPassword(email, password)
                    .then(() => {
                        dispatch(upsertForm(formId, formStates.SUCCESS))
                    })
                    .catch(err => {
                        console.log('in here with an error?')
                    })
            })
            .catch(err => {
                console.log('in here?')
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const updateAuthPassword = (formData, formId) => {
    return dispatch => {
        if (formData.newPassword !== formData.confirmPassword) {
            dispatch(upsertForm(formId, formStates.ERROR, 'New passwords do not match.'))
            return false
        }
        const fireUser = fireauth.currentUser
        const credentials = firebase.auth.EmailAuthProvider.credential(fireUser.email, formData.existingPassword)
        return fireUser.reauthenticateWithCredential(credentials)
            .then(() => {
                fireUser.updatePassword(formData.newPassword)
                    .then(() => {
                        dispatch(upsertForm(formId, formStates.SUCCESS))
                    })
                    .catch(err => {
                        dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
                    })
            })
            .catch(err => {
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const handleFirebaseAuth = (firebaseUser) => {
    return dispatch => {
        dispatch(saveAuth(firebaseUser))
    }
}

export const handleAnonymousFirebaseAuth = (firebaseUser) => {
    return dispatch => {
        dispatch(saveAnonymousAuth(firebaseUser))
    }
}

export const signOut = () => {
    return dispatch => {
        const cookies = new Cookies()
        cookies.remove('user-token-active', { path: '/' })
        fireauth.signOut()
            .then(() => {
                dispatch(killState())
            })
            .catch(err => {
                // TODO: global message declaring we can't do that...
                console.log(err)
            })
    }
}
