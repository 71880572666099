import { combineReducers } from 'redux-immutable'
import { fromJS } from 'immutable'

import apiCacheReducer from './api-cache'
import authReducer from './auth'
import authCodeReducer from './auth-code'
import displayReducer from './display'
import filterReducer from './filters'
import formReducer from './form'
import mapboxSiteReducer from './mapbox-sites'
import scenarioSiteReducer from './site-scenarios'
import sortReducer from './sort'
import uiReducer from './ui-state'

import genericReducer from './generic'

const BLANK_OBJ = fromJS({})
const BLANK_LIST = fromJS([])

const indexReducer = combineReducers({
    account: genericReducer('ACCOUNT', 'OBJECT', BLANK_OBJ),
    accountType: genericReducer('ACCOUNT_TYPE', 'OBJECT', BLANK_OBJ),
    accountUsers: genericReducer('ACCOUNT_USERS', 'LIST', BLANK_LIST),
    adminAccounts: genericReducer('ADMIN_ACCOUNTS', 'LIST', BLANK_LIST),
    adminAccountTypes: genericReducer('ADMIN_ACCOUNT_TYPES', 'LIST', BLANK_LIST),
    adminInvitations: genericReducer('ADMIN_INVITATIONS', 'LIST', BLANK_LIST),
    adminRoles: genericReducer('ADMIN_ROLES', 'LIST', BLANK_LIST),
    adminTopicCategories: genericReducer('ADMIN_TOPIC_CATEGORIES', 'LIST', BLANK_LIST),
    adminTopicDefinitions: genericReducer('ADMIN_TOPIC_DEFINITIONS', 'LIST', BLANK_LIST),
    adminTopicDefinitionSteps: genericReducer('ADMIN_TOPIC_DEFINITION_STEPS', 'LIST', BLANK_LIST),
    adminUsers: genericReducer('ADMIN_USER', 'LIST', BLANK_LIST),
    aggregateTopicCalculations: genericReducer('TOPIC_CALCULATIONS', 'LIST', BLANK_LIST),
    apiCaches: apiCacheReducer,
    auth: authReducer,
    authCode: authCodeReducer,
    calculations: genericReducer('PROFORMA_CALCULATIONS', 'LIST', BLANK_LIST),
    clients: genericReducer('CLIENTS', 'LIST', BLANK_LIST),
    companies: genericReducer('COMPANIES', 'LIST', BLANK_LIST),
    contacts: genericReducer('CONTACTS', 'LIST', BLANK_LIST),
    config: genericReducer('CONFIG_UNITS', 'LIST', BLANK_LIST),
    displays: displayReducer,
    filters: filterReducer,
    forms: formReducer,
    invitations: genericReducer('INVITATIONS', 'LIST', BLANK_LIST),
    images: genericReducer('IMAGES', 'LIST', BLANK_LIST),
    localAppState: genericReducer('LOCAL_APP_STATE', 'OBJECT', BLANK_OBJ),
    // mapSites: genericReducer('SITES', 'LIST', BLANK_LIST),
    mapSites: mapboxSiteReducer,
    people: genericReducer('PEOPLE', 'LIST', BLANK_LIST),
    plans: genericReducer('PLANS', 'LIST', BLANK_LIST),
    proformaTopics: genericReducer('PROFORMA_TOPICS', 'LIST', BLANK_LIST),
    proformaCalculations: genericReducer('PROFORMA_CALCULATIONS', 'LIST', BLANK_LIST),
    projects: genericReducer('PROJECTS', 'LIST', BLANK_LIST),
    projectCalculations: genericReducer('PROJECT_CALCULATIONS', 'LIST', BLANK_LIST),
    projectScorecards: genericReducer('PROJECT_SCORECARDS', 'LIST', BLANK_LIST),
    publicRoles: genericReducer('PUBLIC_ROLES', 'LIST', BLANK_LIST),
    reports: genericReducer('REPORTS', 'LIST', BLANK_LIST),
    reportsWS: genericReducer('REPORTS_WS', 'LIST', BLANK_LIST),
    reportCharts: genericReducer('REPORT_CHARTS', 'LIST', BLANK_LIST),
    reportData: genericReducer('REPORT_DATA', 'LIST', BLANK_LIST),
    reportInvites: genericReducer('REPORT_INVITATIONS', 'LIST', BLANK_LIST),
    reportTemplates: genericReducer('REPORT_TEMPLATES', 'LIST', BLANK_LIST),
    reportViews: genericReducer('REPORT_VIEWS', 'LIST', BLANK_LIST),
    reportTimestamp: genericReducer('REPORT_TIMESTAMPS', 'LIST', BLANK_LIST, 'reportId'),
    reportTopicItemData: genericReducer('REPORT_TOPIC_ITEM_DATA', 'LIST', BLANK_LIST),
    role: genericReducer('ROLE', 'OBJECT', BLANK_OBJ),
    scenarios: genericReducer('SCENARIOS', 'LIST', BLANK_LIST),
    sites: genericReducer('SITES', 'LIST', BLANK_LIST),
    scenarioScorecards: genericReducer('SCENARIO_SCORECARDS', 'LIST', BLANK_LIST),
    // scenarioSites: genericReducer('SCENARIO_SITES', 'LIST', BLANK_LIST),
    scenarioSites: scenarioSiteReducer,
    scorecardOptions: genericReducer('SCORECARD_OPTIONS', 'LIST', BLANK_LIST),
    scorecardTemplates: genericReducer('SCORECARD_TEMPLATES', 'LIST', BLANK_LIST),
    seat: genericReducer('SEAT', 'OBJECT', BLANK_OBJ),
    sorts: sortReducer,
    stripeCards: genericReducer('STRIPE_CARDS', 'LIST', BLANK_LIST),
    stripeCustomer: genericReducer('STRIPE_CUSTOMER', 'OBJECT', BLANK_OBJ),
    stripeInvoices: genericReducer('STRIPE_INVOICES', 'LIST', BLANK_LIST),
    stripeProducts: genericReducer('STRIPE_PRODUCT', 'LIST', BLANK_LIST),
    subscription: genericReducer('SUBSCRIPTION', 'OBJECT', BLANK_OBJ),
    subscriptionSeats: genericReducer('SUBSCRIPTION_SEATS', 'LIST', BLANK_LIST),
    subscriptionSeatUsers: genericReducer('SUBSCRIPTION_SEAT_USERS', BLANK_LIST),
    topicCalculation: genericReducer('TOPIC_CALCULATIONS', 'LIST', BLANK_LIST),
    topicCategories: genericReducer('TOPIC_CATEGORIES', 'LIST', BLANK_LIST),
    topicData: genericReducer('TOPIC_DATA', 'LIST', BLANK_LIST),
    topicDefinitions: genericReducer('TOPIC_DEFINITIONS', 'LIST', BLANK_LIST),
    topicItemCalculation: genericReducer('TOPIC_ITEM_CALCULATIONS', 'LIST', BLANK_LIST),
    topicItemDefinitionSteps: genericReducer('TOPIC_DEFINITION_STEPS', 'LIST', BLANK_LIST),
    topicItems: genericReducer('PROFORMA_TOPIC_ITEMS', 'LIST', BLANK_LIST),
    uiState: uiReducer,
    user: genericReducer('USER', 'OBJECT', BLANK_OBJ)
})

export default indexReducer
