import React from 'react'
import { connect } from 'react-redux'
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js'

import SHSForm from '../../../../utils/shs-form'
import InputElement from '../../../../utils/input'

import { upsertForm } from '../../../../../actions/form'
import { formStates } from '../../../../../constants/helper-states'

const PaymentForm = ({ dispatch, stripe, elements, onSetPaymentInfo, nameOnCard, sendFormId }) => {
    const submitFX = async (formData, formId) => {
        // if we don't have the stripe pieces, can't really do anything...
        if (!stripe || !elements) {
            return
        }

        const cardElement = elements.getElement(CardElement)

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: formData.name_on_card
            }
        })

        if (error) {
            // console.log('[error]', error)
            dispatch(upsertForm(formId, formStates.ERROR, error.message))
        } else if (sendFormId) {
            onSetPaymentInfo(formData.name_on_card, paymentMethod, formId)
        } else {
            onSetPaymentInfo(formData.name_on_card, paymentMethod)
        }
    }

    const successFX = () => {
        // nothing for now
    }

    return (
        <SHSForm submitFX={submitFX} successFX={successFX} buttonDisabled={!stripe ? true : false}>
            <InputElement
                type="text"
                id="name_on_card"
                label="Name on Card"
                default={nameOnCard}
                placeholder="Enter name as it appears on card"
                required />
            <CardElement />
        </SHSForm>
    )
}

const StripeCheckoutForm = (props) => {
    return (
        <ElementsConsumer>
            {({ elements, stripe }) => (
                <PaymentForm elements={elements} stripe={stripe} onSetPaymentInfo={props.onSetPaymentInfo} {...props} />
            )}
        </ElementsConsumer>
    )
}

export default connect()(StripeCheckoutForm)
