import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import AuthBg from '../shared/auth-bg'
import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

import { registerWithEmailAndPass } from '../../actions/auth'
import { routes } from '../../constants/routes'

class Register extends React.Component {
    state = {}

    renderHelmet = () => {
        return (
            <Helmet>
                <title>Create an Account on VisProp Analytics. Register today.</title>
                <meta name="description" content="Ready to start building interactive commercial real estate reports? Create your account today with our easy registration process." />
            </Helmet>
        )
    }

    successFX = () => {
        // nothing for now
    }

    submitFX = (formData, formId) => {
        this.props.dispatch(registerWithEmailAndPass(formData.email, formData.password, formId))
    }

    render = () => {
        return (
            <>
                {this.renderHelmet()}
                <AuthBg />
                <div className="auth-container">
                    <a href="https://www.vispropanalytics.com/">
                        <img src="/assets/img/brand/visprop-logo-white.png" width="260" alt="VisProp Logo" title="VisProp Logo" />
                    </a>
                    <div className="content col-11 col-sm-4">
                        <h1>Start Your Free Trial</h1>
                        <p>
                            To get started, create your login credentials.<br />
                            No credit card required.
                        </p>
                        <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Register" buttonClass="black center">
                            <InputElement
                                type="email"
                                default=""
                                id="email"
                                label="Email Address"
                                placeholder="user@example.com"
                                extraClass="flex-100"
                                required />
                            <InputElement
                                type="password"
                                default=""
                                id="password"
                                label="Password"
                                placeholder="A secret you only know..."
                                extraClass="flex-100"
                                required />
                        </SHSForm>
                    </div>
                    <div className="links col-sm-4">
                        <p>
                            Have an account? <Link to={routes.login}>Login</Link>.
                        </p>
                    </div>
                </div>
            </>
        )
    }
}

export default connect()(Register)
