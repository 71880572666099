import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import moment from 'moment'

import StripeCheckoutForm from './payment-form'
import startCase from 'lodash/startCase'


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const Payment = ({ steps, stepIndex, localAppState, onSetPaymentInfo, onAdvanceStep, paymentMethod, nameOnCard, onSetToCurrentStep }) => {
    // const [ stripe, setStripe ] = useState(null)
    const selfIndex = steps.indexOf('PAYMENT')

    const renderActiveState = () => {
        if (selfIndex !== stepIndex) {
            return null
        }
        return (
            <>
                <StripeCheckoutForm onSetPaymentInfo={onSetPaymentInfo} nameOnCard={nameOnCard} />
                { localAppState.get('registerType') === 'BETA' && localAppState.get('registerTime') > moment().format() &&
                    <div className="mt-2">
                        <span className="clickable sm-txt" onClick={onAdvanceStep}>Skip payment for beta registration.</span>
                    </div>
                }
            </>
        )
    }

    const renderPastState = () => {
        if (selfIndex >= stepIndex) {
            return null
        }
        if (!paymentMethod.card) {
            return (
                <div>No card provided.</div>
            )
        }
        return (
            <div>
                <p className="mb-0">{nameOnCard}</p>
                <p className="mb-0">
                    {startCase(paymentMethod.card.brand)} ending in {paymentMethod.card.last4}
                </p>
                <p className="mb-0">
                    {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
                </p>
                <span className="clickable text-blue sm-txt" onClick={() => onSetToCurrentStep(selfIndex)}>
                    Change Payment
                </span>
            </div>
        )
    }

    const renderFutureState = () => {
        if (selfIndex <= stepIndex) {
            return null
        }
        return (
            <>&nbsp;</>
        )
    }

    const renderSectionHeader = (clickable = false) => {
        return (
            <>
                <h2>Step 2: Payment Information</h2>
                { selfIndex === stepIndex &&
                    <p>
                        Enter your payment information below.<br />
                        Your credit card will be charged for the first month of your subscription.<br />
                        You can modify or cancel your plan at any time.
                    </p>
                }
            </>
        )
    }

    if (stepIndex === steps.length - 1) {
        return null
    }
    return (
        <Elements stripe={stripePromise} onSetPaymentInfo={onSetPaymentInfo}>
            <div className="content">
                {renderSectionHeader()}
                {renderActiveState()}
                {renderPastState()}
                {renderFutureState()}
            </div>
        </Elements>
    )
}

export default Payment
