import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import AuthBg from '../shared/auth-bg'
import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

import { callApi } from '../../actions/api'
import { routes, apiRoutes } from '../../constants/routes'

class CreateAccount extends React.Component {
    state = {

    }

    successFX = () => {
        this.props.history.push(routes.router)
    }

    submitFX = (formData, formId) => {
        this.props.dispatch(callApi(apiRoutes.createAccount, formData, formId))
    }

    render = () => {
        return (
            <>
                <AuthBg />
                <div className="auth-container">
                    <Link to={routes.index}>
                        <img src="/assets/img/brand/visprop-logo-white.png" width="260" alt="VisProp Logo" title="VisProp Logo" />
                    </Link>
                    <div className="content col-11 col-sm-4">
                        <h1>Account Information</h1>
                        <p>Please provide a name for your account.</p>
                        <p>
                            When you click submit we will start your 14 day free trial of the Basic product.
                            During your trial you can upgrade to other products.
                        </p>
                        <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Submit">
                            <InputElement
                                type="text"
                                default=""
                                id="name"
                                label="Account Name"
                                placeholder="Acme Real Estate Co."
                                helperText="Provide the name of your business"
                                required />
                        </SHSForm>
                    </div>
                </div>
            </>
        )
    }
}

export default connect()(CreateAccount)
