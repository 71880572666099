import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'

import { fetchApiDataIfNeeded } from '../../actions/api'
import { apiResourceEndpoint } from '../../constants/routes'

import AuthBg from '../shared/auth-bg'

import PlanSelection from '../app/account/plans/subscription/plan-selection'
import Payment from '../app/account/plans/subscription/payment'
import ReviewOrder from '../app/account/plans/subscription/review-order'
import SubscriptionReceipt from '../app/account/plans/subscription/receipt'

import '../../assets/scss/settings.scss'

const CreateSubscription = ({ dispatch, localAppState }) => {
    const [ priceId, setPriceId ] = useState(null)
    const [ productId, setProductId ] = useState(null)
    const [ rate, setRate ] = useState(0)
    const [ stepIndex, setStepIndex ] = useState(0)
    const [ paymentMethod, setPaymentMethod ] = useState(null)
    const [ nameOnCard, setNameOnCard ] = useState('')
    const [ promoCode, setPromoCode ] = useState(null)
    const [ productName, setProductName ] = useState(null)
    const steps = ['PLAN_SELECTION', 'PAYMENT', 'REVIEW', 'RECEIPT']

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('subscription', 'LIST')))
    }, [ dispatch ])

    // const { account } = this.props
    // if (account && account.hasIn(['_computed', 'subscriptionStatusValue']) && account.getIn(['_computed', 'subscriptionStatusValue']) === 'ACTIVE') {
    //     this.props.history.push(routes.router)
    // }

    const setPromo = (promo) => {
        setPromoCode(promo)
    }
    // TODO: we need to add rate data here too so we can send it up as well
    const setProductInfo = (stripeProduct) => {
        setProductId(stripeProduct.get('id'))
        setPriceId(stripeProduct.get('priceId'))
        setRate(stripeProduct.get('unitAmount'))
        setProductName(stripeProduct.get('name'))
        advanceStep()
    }

    const setPaymentInfo = (name, paymentData) => {
        setNameOnCard(name)
        setPaymentMethod(paymentData)
        setStepIndex(stepIndex + 1)
    }

    const setToCurrentStep = (newIndex) => {
        setStepIndex(newIndex)
    }

    const advanceStep = () => {
        setStepIndex(stepIndex + 1)
    }

    const commonProps = {
        rate,
        priceId,
        productId,
        stepIndex,
        steps,
        paymentMethod,
        nameOnCard,
        promoCode,
        productName,
        onAdvanceStep: advanceStep,
        onSetToCurrentStep: setToCurrentStep,
        localAppState
    }
    return (
        <>
            <AuthBg />
            <div className="auth-container subscription">
                <Link to={routes.index}>
                    <img src="/assets/img/brand/visprop-logo-white.png" width="260" alt="VisProp Logo" title="VisProp Logo" />
                </Link>
                <div className="content">
                    <h1 className="text-center">VisProp Subscription</h1>
                </div>
                <PlanSelection {...commonProps} onSetProductInfo={setProductInfo} />
                <Payment {...commonProps} onSetPaymentInfo={setPaymentInfo} />
                <ReviewOrder {...commonProps} onSetPromo={setPromo} />
                <SubscriptionReceipt {...commonProps} />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        subscription: state.get('subscription'),
        account: state.get('account'),
        localAppState: state.get('localAppState')
    }
}

export default connect(mapStateToProps)(CreateSubscription)
