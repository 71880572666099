import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

import AuthBg from '../shared/auth-bg'
import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

import { resetPasswordWithCode, clearAuthCode } from '../../actions/auth'
import { routes } from '../../constants/routes'

class PasswordReset extends React.Component {
    state = {
        isMissingCode: false
    }

    componentDidMount = () => {
        if (this.props.authCode.isEmpty()) {
            this.setState({ isMissingCode: true })
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearAuthCode())
    }

    successFX = () => {
        // nothing for now?
    }

    submitFX = (formData, formId) => {
        const { dispatch, authCode } = this.props
        dispatch(resetPasswordWithCode(authCode.get('authCode'), authCode.get('authTempEmail'), formData.password, formId))
    }

    render = () => {
        if (this.state.isMissingCode) {
            return <Redirect to={routes.login} />
        }
        return (
            <>
                <AuthBg />
                <div className="auth-container">
                    <Link to={routes.index}>
                        <img src="/assets/img/brand/visprop-logo-white.png" width="260" alt="VisProp Logo" title="VisProp Logo" />
                    </Link>
                    <div className="content col-11 col-sm-4">
                        <h1>Reset Your Password</h1>
                        <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Reset Password" buttonClass="black center">
                            <InputElement
                                default={this.props.authCode.get('authTempEmail')}
                                label="Email"
                                extraClass="flex-100"
                                readOnly />
                            <InputElement
                                type="password"
                                default=""
                                id="password"
                                label="Password"
                                placeholder="Something secret only you know..."
                                extraClass="flex-100"
                                required />
                        </SHSForm>
                    </div>
                    <div className="links col-sm-4">
                        <p>
                            <Link to={routes.login}>Return to login page</Link>
                        </p>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authCode: state.get('authCode')
    }
}

export default connect(mapStateToProps)(PasswordReset)
