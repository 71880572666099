import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { fetchApiDataIfNeeded } from '../../../../../actions/api'
import { apiResourceEndpoint } from '../../../../../constants/routes'

import * as MASKS from '../../../../utils/input-masks'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state) => {
    return {
        products: state.get('stripeProducts')
    }
}

const PlanSelection = ({ dispatch, steps, stepIndex, onSetToCurrentStep, products, onSetProductInfo, productId, rate }) => {
    const selfIndex = steps.indexOf('PLAN_SELECTION')

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('stripe/all-product', 'LIST')))
    }, [dispatch])

    const renderActiveState = () => {
        if (selfIndex !== stepIndex || products.size < 1) {
            return null
        }
        let options = []
        products.sort((a, b) => {
            if (a.get('unitAmount') > b.get('unitAmount')) {
                return 1
            }
            return -1
        }).forEach(product => {
            options.push(
                <div key={product.get('id')} className="product-options">
                    <div className="product-info">
                        <h3 className="mb-0">{product.get('name')}</h3>
                        {product.get('description') &&
                            <p>{product.get('description')}</p>
                        }
                        <span>${(product.get('unitAmount') / 100).toLocaleString('en-US')} per {product.get('interval')}</span>
                    </div>
                    <div className="action-holder">
                        <span className="selectable" onClick={() => onSetProductInfo(product)}>
                            Select
                        </span>
                    </div>
                </div>
            )
        })
        return options
    }

    const renderPastState = () => {
        if (selfIndex >= stepIndex) {
            return null
        }
        const selectedProduct = products.find(p => p.get('id') === productId)
        // let planObj = planTypes.find(pt => pt.interval === interval)
        // let planData = this.props.planTypes.find(pt => pt.interval === this.props.priceData.recurring.interval)
        // const price = this.getPrice()
        return (
            <div className="plan-summary">
                <h3 className="mb-0">{selectedProduct.get('name')}</h3>
                <p>{MASKS.dollarMask.resolve((rate / 100).toString())}</p>
                <span className="clickable text-blue sm-txt" onClick={() => onSetToCurrentStep(selfIndex)}>Change Plan</span>
            </div>
        )
    }

    const renderFutureState = () => {
        if (selfIndex <= stepIndex) {
            return null
        }
        return (
            <>
                Select your VisProp product.
            </>
        )
    }

    const renderSectionHeader = (clickable = false) => {
        return (
            <div className="w-100">
                <h2>Step 1: Product Selection</h2>
                { selfIndex === stepIndex &&
                    <p>
                        Select a product.
                    </p>
                }
            </div>
        )
    }

    if (stepIndex === steps.length - 1) {
        return null
    }
    return (
        <div className="content">
            {renderSectionHeader()}
            {renderActiveState()}
            {renderPastState()}
            {renderFutureState()}
        </div>
    )
}

export default connect(mapStateToProps)(PlanSelection)
