import moment from 'moment'
// return currency formatting
// TODO: need to work in some parametes to control the options...
export const formatCurrency = (value, digits = 2, min = 0) => {
    return parseFloat(value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: min,
        maximumFractionDigits: digits
    })
}

// return a total with the numerical unit
// TODO: work in more parametes and controls...
export const formatTotalWithUnit = (total, numDigits = 3) => {
    let value = total
    let unit = ''
    let formatDigits = numDigits
    const digits = Math.ceil(Math.log10(Math.abs(total) + 1))
    if (digits < 4) {
        formatDigits = 2
    } else if (digits >= 4 && digits < 7) {
        value = Math.round((total * 1000) / 1000) / 1000
        unit = 'k'
    } else if (digits >= 7 && digits < 10) {
        value = Math.round((total * 1000) / 1000000) / 1000
        unit = 'M'
    } else {
        value = Math.round((total * 1000) / 1000000000) / 1000
        unit = 'B'
    }
    return `${formatCurrency(value, formatDigits)}${unit}`
}

export const monthsToYears = (months) => {
    let years = removeInsignificantZeros(months / 12, 1)
    return `${years} Year${years === 0 ? '' : 's'}`
}

export const dateDisplay = (date, format = 'MM/DD/YYYY') => {
    return moment(date).format(format)
}

export const toDecimals = (num, decimals = 2) => {
    return Number(num).toFixed(decimals)
}

export const removeInsignificantZeros = (num, decimals = 2) => {
    return Number(num).toFixed(decimals).toString()
}

export const roundToUnit = (total) => {
    let value = total
    const digits = Math.ceil(Math.log10(total + 1))
    if (digits < 4) {
        // do nothing
    } else if (digits >= 4 && digits < 7) {
        value = Math.round((total * 1000) / 1000) / 1000 * 1000
    } else if (digits >= 7 && digits < 10) {
        value = Math.round((total * 1000) / 1000000) / 1000 * 1000000
    } else {
        value = Math.round((total * 1000) / 1000000000) / 1000 * 1000000000
    }
    return value
}
