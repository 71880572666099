import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { signOut } from '../../actions/auth'
import { routes } from '../../constants/routes'

class Logout extends React.Component {
    state = {}

    componentDidMount = () => {
        this.props.dispatch(signOut())
        this.props.history.push(routes.login)
    }

    render = () => {
        return (
            <div>Logging out...</div>
        )
    }
}

export default withRouter(connect()(Logout))
