import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// import moment from 'moment'

import { fetchConfigData } from '../../../../../actions/api'
import { apiResourceEndpoint, routes } from '../../../../../constants/routes'

import { formatCurrency } from '../../../../utils/formatters'

const SubscriptionReceipt = ({ steps, productId, priceId, promoCode, rate, productName, stepIndex }) => {
    const [ totals, setTotals ] = useState(null)
    const selfIndex = steps.indexOf('RECEIPT')

    useEffect(() => {
        fetchTotals()
    }, [ productId ])

    const fetchTotals = async (promo = null) => {
        let totalParams = {
            price_id: priceId,
            quantity: 1,
            promo: promoCode
        }
        let totalData = null
        totalData = await fetchConfigData(apiResourceEndpoint('stripe/total', 'LIST').url, totalParams)
        setTotals(totalData)
    }

    const renderTotals = () => {
        const subtotal = rate / 100
        let promoTotal = 0
        if (totals && totals.promoIsValid) {
            promoTotal = -1 * subtotal * (totals.promoValue / 100)
        }
        let taxes = 0
        if (totals && totals.taxRate) {
            taxes = (subtotal + promoTotal) * (totals.taxRate / 100)
        }
        const total = subtotal + promoTotal + taxes

        if (!totals) {
            return null
        }
        return (
            <table className="table mb-0">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Rate</th>
                        <th className="text-right">Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    {/* <tr>
                        <td>Free Trial ({moment().format('MMMM D, Y')} - {moment().add(14, 'days').format('MMMM D, Y')})</td>
                        <td>$0.00</td>
                        <td className="text-right">$0.00</td>
                    </tr> */}
                    <tr>
                        <td>{productName}</td>
                        <td>{formatCurrency(rate / 100)}</td>
                        <td className="text-right">{formatCurrency(subtotal, 2, 2)}</td>
                    </tr>
                    { totals && totals.promoIsValid &&
                        <tr>
                            <td>{totals.promoDescription}</td>
                            <td>1</td>
                            <td>{promoCode}</td>
                            <td className="text-right">{formatCurrency(promoTotal)}</td>
                        </tr>
                    }
                    <tr>
                        <td>Sales Tax</td>
                        <td>{totals.taxRate}%</td>
                        <td className="text-right">{formatCurrency(taxes, 2, 2)}</td>
                    </tr>
                    <tr>
                        <td>Total</td>
                        <td>&nbsp;</td>
                        <td className="text-right">{formatCurrency(total, 2, 2)}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    if (selfIndex !== stepIndex) {
        return null
    }

    return (
        <div className="content p-4">
            <h2>Order Confirmation</h2>
            {renderTotals()}
            <Link to={routes.router}>
                Continue to Dashboard
            </Link>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        subscription: state.get('subscription')
    }
}

export default connect(mapStateToProps)(SubscriptionReceipt)
