// auth
export const SAVE_AUTH = 'SAVE_AUTH'
export const SAVE_ANONYMOUS_AUTH = 'SAVE_ANONYMOUS_AUTH'
export const REMOVE_AUTH = 'REMOVE_AUTH'
export const SAVE_AUTH_HANDLER = 'SAVE_AUTH_HANDLER'
export const REMOVE_AUTH_HANDLER = 'REMOVE_AUTH_HANDLER'

// redux helper actions
export const UPSERT_FORM = 'UPSERT_FORM'
export const UPSERT_FETCHING = 'UPSERT_FETCHING'
export const CLEAR_FORM = 'CLEAR_FORM'
export const CLEAR_FETCHING = 'CLEAR_FETCHING'

// kill state
export const KILL_STATE = 'KILL_STATE'
