import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

// import { fireauth } from '../../firebase/firebase'
import { handleFirebaseAuthCode } from '../../actions/auth'
import { routes } from '../../constants/routes'

import AuthBg from '../shared/auth-bg'

class AuthHandler extends React.Component {
    state = {
        authCode: '',
        actionType: '',
        isValidCode: false,
        hasError: false
    }

    componentDidMount = () => {
        const urlParams = new URLSearchParams(this.props.location.search)

        // TODO: should we validate these?
        const authCode = urlParams.get('oobCode')
        const actionType = urlParams.get('mode')
        this.setState({ authCode, actionType })
        this.props.dispatch(handleFirebaseAuthCode(authCode, actionType))
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.authCode.has('authCode') && !prevProps.authCode.has('authCode')) {
            this.setState({ isValidCode: true })
        }
        if (this.props.authCode.get('hasError') && !prevProps.authCode.get('hasError')) {
            this.setState({ hasError: true })
        }
    }

    render = () => {
        if (this.state.isValidCode) {
            switch (this.state.actionType) {
            case 'resetPassword':
                return <Redirect to={routes.passwordReset} />
            default:
                return null
                // (
                //     <div className="container mt-4">
                //         <div className="col-4 offset-4">
                //             <div className="alert alert-danger w-100 text-center">
                //                 <p>Need to handle the situation where action type is {this.state.actionType}</p>
                //                 <Link to={routes.passwordReset}>Return to Login</Link>
                //             </div>
                //         </div>
                //     </div>
                // )
            }
        }
        return (
            <>
                <AuthBg />
                <div className="auth-container">
                    <Link to={routes.index}>
                        <img src="/assets/img/brand/visprop-logo-white.png" width="260" alt="VisProp Logo" title="VisProp Logo" />
                    </Link>
                    <div className="content col-11 col-sm-4">
                        { this.state.hasError
                            ? (
                                <div className="alert alert-danger w-100 text-center">
                                    <p>There was a problem with the reset password token. It may have expired or already been used. Please try again.</p>
                                    <Link to={routes.login}>Return to Login</Link>
                                </div>
                            )
                            : <div>Validating the authorization code...</div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authCode: state.get('authCode')
    }
}

export default connect(mapStateToProps)(AuthHandler)
