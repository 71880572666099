import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { clearErrorCode } from '../../actions/ui'
import { routes } from '../../constants/routes'

import PublicNav from '../navs/public'

class ErrorPage extends React.Component {
    state = {

    }

    componentDidMount = () => {
        this.props.dispatch(clearErrorCode())
    }

    render = () => {
        return (
            <div>
                <PublicNav />
                <div className="container">
                    <div className="row">
                        <div className="col text-center mt-5">
                            <img src="/layout/404-notice.png" alt="404 error message" className="mb-4" />
                            <h1>Page Not Found</h1>
                            <p>
                                We encountered an error trying to load this page.<br />
                                You can either return to the previous page, visit our homepage or contact our support team.
                            </p>
                            <Link to={routes.index} className="btn blue">Visit Homepage</Link>
                            <Link to={routes.contact} className="btn add ml-2">Contact Support</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(ErrorPage)
