import pathToRegexp from 'path-to-regexp'

export const API_METHODS = { POST: 'POST', GET: 'GET', PUT: 'PUT', DELETE: 'DELETE' }

export const publicRoutes = {
    sharedIndex: '/shared/',
    // NOTE: shareCode will be reportId + '-' + inviteId
    sharedReportView: '/shared/reports/:shareCode/'
}

export const routes = {
    index: '/',
    hello: '/hello/',
    login: '/login/',
    logout: '/logout/',
    contact: '/',
    register: '/register/',
    createUser: '/create-user/',
    createAccount: '/create-account/',
    createSubscription: '/create-subscription/',
    needSubscriptionSeat: '/no-seat-assignment/',
    privacyPolicy: '/privacy-policy/',
    termsOfUse: '/terms-of-use/',
    passwordHelp: '/password-help/',
    passwordReset: '/password-reset/',
    invitation: '/invitation/:inviteCode/',
    authHandler: '/auth-handler/',
    router: '/router/',
    errorPage: '/error/',
    // dashboard: '/dashboard/',
    appIndex: '/app/',
    projectsIndex: '/app/projects/',
    createProjectOld: '/app/projects/create-old/',
    createProject: '/app/projects/create/',
    viewProjectOld: '/app/projects/:projectId/old/',
    viewProject: '/app/projects/:projectId/',
    copyProject: '/app/projects/:projectId/copy/',
    projectSettings: '/app/projects/:projectId/settings/',
    projectScorecard: '/app/projects/:projectId/scorecard/',

    projectContactsIndex: '/app/projects/:projectId/contacts/',
    projectContactsModalAddCompany: '/:pagePath*/modal/p/:projectId/add-company/',
    projectContactsModalAddPerson: '/:pagePath*/modal/p/:projectId/add-person/',

    scenariosIndex: '/app/projects/:projectId/scenarios/',
    reportsIndex: '/app/projects/:projectId/reports/',
    sampleReports: '/app/projects/:projectId/sample-reports/',
    createReport: '/app/projects/:projectId/reports/create/',
    viewReport: '/app/projects/:projectId/reports/:reportId/',
    editReport: '/app/projects/:projectId/reports/:reportId/edit/',
    copyReport: '/app/projects/:projectId/reports/:reportId/copy/',

    reportModalManageSharing: '/:pagePath*/modal/reports/p/:projectId/r/:reportId/share',

    sharedIndex: '/shared/',
    // note shareCode will be reportId + '-' + inviteId
    sharedReportView: '/shared/reports/:shareCode/',

    modalFaqsScenarios: '/:pagePath*/modal/faqs/what-is-a-scenario/',
    modalFaqsProforma: '/:pagePath*/modal/faqs/how-proforma-works/',
    modalFaqsCustomEntry: '/:pagePath*/modal/faqs/understanding-custom-entries/',

    createScenario: '/app/projects/:projectId/scenarios/create/',
    siteModal: '/:pagePath*/modal/sites/',
    siteModalCreateSite: '/:pagePath*/modal/sites/create/',
    siteModalViewSite: '/:pagePath*/modal/sites/:siteId/',
    siteModalEditSite: '/:pagePath*/modal/sites/:siteId/edit/',

    scenarioDashboard: '/app/projects/:projectId/scenarios/:scenarioId/',
    editScenario: '/app/projects/:projectId/scenarios/:scenarioId/edit/',
    copyScenario: '/app/projects/:projectId/scenarios/:scenarioId/copy/',
    scenarioProforma: '/app/projects/:projectId/scenarios/:scenarioId/proforma/',
    guidedProforma: '/app/projects/:projectId/scenarios/:scenarioId/proforma/guided/:categoryId/:sectionId/:stepId/',
    exploringProformaSummary: '/app/projects/:projectId/scenarios/:scenarioId/proforma/exploring/:categoryId/',
    exploringProformaSectionStep: '/app/projects/:projectId/scenarios/:scenarioId/proforma/exploring/:categoryId/:sectionId/',
    advancedProformaEntry: '/app/projects/:projectId/scenarios/:scenarioId/proforma/advanced/',
    scenarioScorecard: '/app/projects/:projectId/scenarios/:scenarioId/scorecard/',

    companiesIndex: '/app/companies/',
    companiesCreate: '/app/companies/create/',
    companiesView: '/app/companies/:companyId/',
    companiesEdit: '/app/companies/:companyId/edit/',
    companiesModalAddProject: '/:pagePath*/modal/c/:companyId/add-project/',
    companiesModalAddPerson: '/:pagePath*/modal/c/:companyId/add-person/',

    peopleIndex: '/app/people/',
    peopleCreate: '/app/people/create/',
    peopleView: '/app/people/:personId/',
    peopleEdit: '/app/people/:personId/edit/',
    peopleModalAddProject: '/:pagePath*/modal/pe/:personId/add-project/',

    clientsIndex: '/app/clients/',
    createClient: '/app/clients/create/',
    viewClient: '/app/clients/:clientId/',
    editClient: '/app/clients/:clientId/edit/',
    clientModalCreate: '/:pagePath*/modal/client/create/:projectId/',

    contactIndex: '/app/clients/:clientId/contact/',
    viewContact: '/app/clients/:clientId/contact/:contactId/',
    editContact: '/app/clients/:clientId/contact/:contactId/edit/',
    createContact: '/app/clients/:clientId/contact/create/',

    commissions: '/app/commissions/',
    appSettings: '/app/settings/',

    appAccount: '/app/account/',
    appAccountProfile: '/app/account/profile/',
    appAccountProfileEdit: '/app/account/profile/edit/',
    appAccountProfileAuth: '/app/account/profile/auth',
    appAccountUsers: '/app/account/users/',
    appAccountSubscription: '/app/account/subscription/',
    appAccountNotifications: '/app/account/notifications/',

    appAccountUsersModalAddPerson: '/:pagePath*/modal/user/create/',
    appAccountUsersModalEditPerson: '/:pagePath*/modal/user/:userId/',
    appAccountUsersModalManageSeat: '/:pagePath*/modal/seat/:seatId/',
    appAccountSubscriptionModal: '/:pagePath*/modal/subscription/',

    adminIndex: '/admin/',
    adminAccountsIndex: '/admin/accounts/',
    adminViewAccount: '/admin/accounts/:accountId/',
    adminAccountInvitation: '/admin/accounts/:accountId/invite/',
    adminUsersIndex: '/admin/users/',
    adminUsersInvite: '/admin/users/invite/',
    adminUsersInvitationList: '/admin/users/invitations/',

    adminSettingsIndex: '/admin/settings/',
    adminSettingsAccountTypeIndex: '/admin/settings/account-types/',
    adminSettingsAccountTypeView: '/admin/settings/account-types/:accountTypeId/',
    adminSettingsProformaIndex: '/admin/settings/proformas/',
    adminSettingsProformaCategoryView: '/admin/settings/proformas/:categoryId/',
    adminSettingsProformaCategoryItemView: '/admin/settings/proformas/:categoryId/:itemId/'
}

export const publicApiRoutes = {
    loadSharedReport: { url: '/shared/reports/:shareCode/', action: 'SAVE_REPORT', method: API_METHODS.GET },
    fetchPublicProject: { url: '/shared/projects/:shareCode/', action: 'SAVE_PROJECT', method: API_METHODS.GET },
    fetchPublicScenarios: { url: '/shared/scenarios/:shareCode/', action: 'SAVE_SCENARIOS', method: API_METHODS.GET },
    fetchPublicTopicItemData: { url: '/shared/topic-item-data/:shareCode/', action: 'SAVE_TOPIC_ITEM_REPORT_DATA', method: API_METHODS.GET },
    fetchPublicProjectScorecard: { url: '/shared/project-scorecard/:shareCode/', action: 'SAVE_PROJECT_SCORECARD_OPTIONS', method: API_METHODS.GET },
    fetchPublicReportScorecards: { url: '/shared/report-scorecards/:shareCode/', action: 'SAVE_SCENARIO_SCORECARD_VALUES', method: API_METHODS.GET }
}

export const apiResourceEndpoint = (resource, action, id = null, extraPath = '') => {
    let routeObj = {
        url: `/${resource}s/`,
        method: API_METHODS.GET
    }
    // adjust the route
    if (action === 'UPDATE' || action === 'VIEW' || action === 'DELETE') {
        routeObj.url = routeObj.url + `${id}/`
    }
    // adjust the medthod
    if (action === 'CREATE' || action === 'UPDATE') {
        routeObj.method = API_METHODS.POST
    } else if (action === 'DELETE') {
        routeObj.method = API_METHODS.DELETE
    }
    // handle extrapath
    if (extraPath !== '') {
        routeObj.url = routeObj.url + extraPath + '/'
    }
    return routeObj
}

export const apiRoutes = {
    getInvitation: { url: '/auth/get-invitation/:inviteCode/', action: 'SAVE_INVITATION', method: API_METHODS.GET },
    createAccount: { url: '/accounts/', action: 'SAVE_USER', method: API_METHODS.POST },

    createUser: { url: '/users/', action: 'SAVE_USER', method: API_METHODS.POST },
    editUserProfile: { url: '/users/edit/', action: 'SAVE_USER', method: API_METHODS.POST },
    listAccountUsers: { url: '/users/', action: 'SAVE_ALL_USERS', method: API_METHODS.GET },

    listPlanOptions: { url: '/plans/', action: 'SAVE_PLANS', method: API_METHODS.GET },
    updatePlan: { url: '/plans/', action: 'SAVE_USER_PLAN', method: API_METHODS.POST },

    // clients
    createClient: { url: '/clients/', action: 'SAVE_CLIENT', method: API_METHODS.POST },
    updateClient: { url: '/clients/:clientId/', action: 'SAVE_CLIENT', method: API_METHODS.POST },
    fetchClient: { url: '/clients/:clientId/', action: 'SAVE_CLIENT', method: API_METHODS.GET },
    fetchClients: { url: '/clients/', action: 'SAVE_CLIENTS', method: API_METHODS.GET },
    fetchClientsByIds: { url: '/clients/byid?ids=:clientIds', action: 'SAVE_CLIENTS', method: API_METHODS.GET },
    deleteClient: { url: '/clients/:clientId/', action: 'REMOVE_CLIENT', method: API_METHODS.DELETE },
    listClientProjects: { url: '/clients/:clientId/list-projects/', action: 'SAVE_PROJECTS', method: API_METHODS.GET },

    // contacts
    createContact: { url: '/clients/:clientId/contacts/', action: 'SAVE_CONTACT', method: API_METHODS.POST },
    updateContact: { url: '/clients/:clientId/contacts/:contactId/', action: 'SAVE_CONTACT', method: API_METHODS.POST },
    fetchContact: { url: '/clients/:clientId/contacts/:contactId/', action: 'SAVE_CONTACT', method: API_METHODS.GET },
    fetchContacts: { url: '/clients/:clientId/contacts/', action: 'SAVE_CONTACTS', method: API_METHODS.GET },
    fetchAllContacts: { url: '/clients/contacts/all/', action: 'SAVE_CONTACTS', method: API_METHODS.GET },
    deleteContact: { url: '/clients/:clientId/contacts/:contactId/', action: 'REMOVE_CONTACT', method: API_METHODS.DELETE },

    // contact to project
    createAndAddContactToProject: { url: '/projects/:projectId/create-contact/', action: 'SAVE_CONTACT', method: API_METHODS.POST },
    addContactToProject: { url: '/projects/:projectId/add-contact/:contactId/', action: 'SAVE_CONTACT', method: API_METHODS.POST },
    removeContactFromProject: { url: '/projects/:projectId/remove-contact/:contactId/', action: 'SAVE_CONTACT', method: API_METHODS.POST },
    searchContacts: { url: '/clients/contacts/search/:projectId/', action: 'SAVE_CONTACTS', method: API_METHODS.GET },

    // projects
    createProject: { url: '/projects/', action: 'SAVE_PROJECT', method: API_METHODS.POST },
    updateProject: { url: '/projects/:projectId/', action: 'SAVE_PROJECT', method: API_METHODS.POST },
    updateProjectInfo: { url: '/projects/:projectId/info/', action: 'SAVE_PROJECT', method: API_METHODS.POST },
    updateProjectDefaults: { url: '/projects/:projectId/defaults/', action: 'SAVE_PROJECT', method: API_METHODS.POST },
    updateProjectType: { url: '/projects/:projectId/project-type/', action: 'SAVE_PROJECT', method: API_METHODS.POST },
    listProjects: { url: '/projects/', action: 'SAVE_PROJECTS', method: API_METHODS.GET },
    fetchProject: { url: '/projects/:projectId/', action: 'SAVE_PROJECT', method: API_METHODS.GET },
    deleteProject: { url: '/projects/:projectId/', action: 'REMOVE_PROJECT', method: API_METHODS.DELETE },
    listProjectContacts: { url: '/projects/:projectId/contacts/', action: 'SAVE_CONTACTS', method: API_METHODS.GET },

    // reports
    createReport: { url: '/projects/:projectId/reports/', action: 'SAVE_REPORT', method: API_METHODS.POST },
    listReports: { url: '/projects/:projectId/reports/', action: 'SAVE_ALL_REPORTS', method: API_METHODS.GET },
    fetchReport: { url: '/projects/:projectId/reports/:reportId/', action: 'SAVE_REPORT', method: API_METHODS.GET },
    updateReport: { url: '/projects/:projectId/reports/:reportId/', action: 'SAVE_REPORT', method: API_METHODS.POST },
    // USING THIS ONE!
    fetchReportCalcDate: { url: '/reports/:reportId/calculation-date/', action: 'SAVE_REPORT_DATE', method: API_METHODS.GET },
    refreshReportData: { url: '/projects/:projectId/reports/:reportId/refresh/', action: 'SAVE_REPORT', method: API_METHODS.GET },
    fetchReportTemplates: { url: '/reports/templates/', action: 'SAVE_REPORT_TEMPLATES', method: API_METHODS.GET },

    // report invites
    fetchReportInvites: { url: '/projects/:projectId/reports/:reportId/invites/', action: 'SAVE_ALL_REPORT_INVITES', method: API_METHODS.GET },
    createReportInvite: { url: '/projects/:projectId/reports/:reportId/invites/', action: 'SAVE_REPORT_INVITE', method: API_METHODS.POST },
    createReportEmailInvite: { url: '/projects/:projectId/reports/:reportId/email-invitation/', action: 'SAVE_REPORT_INVITE', method: API_METHODS.POST },
    revokeReportInvitation: { url: '/projects/:projectId/reports/:reportId/invites/:inviteId/', action: 'REMOVE_REPORT_INVITE', method: API_METHODS.DELETE },
    resendReportInvitation: { url: '/projects/:projectId/reports/:reportId/invites/:inviteId/resend/', action: 'SAVE_REPORT_INVITE', method: API_METHODS.POST },

    // sites
    createSite: { url: '/sites/', action: 'SAVE_SITE', method: API_METHODS.POST },
    fetchSite: { url: '/sites/:siteId/', action: 'SAVE_SITE', method: API_METHODS.GET },
    updateSite: { url: '/sites/:siteId/', action: 'SAVE_SITE', method: API_METHODS.POST },
    listSites: { url: '/sites/', action: 'SAVE_ALL_SITES', method: API_METHODS.GET },
    uploadSitePhoto: { url: '/sites/:siteId/photo/', action: 'SAVE_SITE', method: API_METHODS.POST },

    // scenarios
    createScenario: { url: '/projects/:projectId/scenarios/', action: 'SAVE_SCENARIO', method: API_METHODS.POST },
    updateScenario: { url: '/projects/:projectId/scenarios/:scenarioId/', action: 'SAVE_SCENARIO', method: API_METHODS.POST },
    updateScenarioName: { url: '/projects/:projectId/scenarios/:scenarioId/name/', action: 'SAVE_SCENARIO', method: API_METHODS.POST },
    updateScenarioDefaults: { url: '/projects/:projectId/scenarios/:scenarioId/defaults/', action: 'SAVE_SCENARIO', method: API_METHODS.POST },
    fetchScenario: { url: '/projects/:projectId/scenarios/:scenarioId/', action: 'SAVE_SCENARIO', method: API_METHODS.GET },
    deleteScenario: { url: '/projects/:projectId/scenarios/:scenarioId/', action: 'REMOVE_SCENARIO', method: API_METHODS.DELETE },
    fetchScenarios: { url: '/projects/:projectId/scenarios/', action: 'SAVE_SCENARIOS', method: API_METHODS.GET },
    fetchScenarioSites: { url: '/projects/:projectId/scenarios/:scenarioId/sites/', action: 'SAVE_ALL_SITES', method: API_METHODS.GET },
    removeSiteFromScenario: { url: '/projects/:projectId/scenarios/:scenarioId/sites/:siteId/remove/', action: 'SAVE_SITE', method: API_METHODS.POST },
    addSiteToScenario: { url: '/projects/:projectId/scenarios/:scenarioId/sites/:siteId/', action: 'SAVE_SITE', method: API_METHODS.POST },

    // calculations
    fetchScenarioCalculations: { url: '/scenarios/:scenarioId/summary-calculations/', action: 'SAVE_SCENARIO_CALCULATION', method: API_METHODS.GET },
    fetchBaselineCalculation: { url: '/projects/:projectId/baseline-calculation/', action: 'SAVE_SCENARIO_CALCULATION', method: API_METHODS.GET },
    fetchProjectCalculations: { url: '/projects/:projectId/project-calcluations/', action: 'SAVE_PROJECT_CALCULATIONS', method: API_METHODS.GET },

    // scorecards
    fetchScorecardTemplate: { url: '/scorecards/project-template/:projectId/', action: 'SAVE_SCORECARD_TEMPLATE', method: API_METHODS.GET },
    fetchProjectScorecard: { url: '/projects/:projectId/scorecard/', action: 'SAVE_PROJECT_SCORECARD_OPTIONS', method: API_METHODS.GET },
    setProjectScorecardOption: { url: '/projects/:projectId/scorecard/:optionId/', action: 'SAVE_PROJECT_SCORECARD_OPTION', method: API_METHODS.POST },
    fetchScenarioScorecard: { url: '/projects/:projectId/scenarios/:scenarioId/scorecard/', action: 'SAVE_SCENARIO_SCORECARD_VALUES', method: API_METHODS.GET },
    fetchReportScorecards: { url: '/projects/:projectId/reports/:reportId/scorecard/', action: 'SAVE_SCENARIO_SCORECARD_VALUES', method: API_METHODS.GET },
    setScenarioScorecardValue: { url: '/projects/:projectId/scenarios/:scenarioId/scorecard/:optionId/', action: 'SAVE_SCENARIO_SCORECARD_VALUE', method: API_METHODS.POST },
    createScorecardFeature: { url: '/projects/:projectId/scorecard/feature/', action: 'SAVE_PROJECT_SCORECARD_OPTION', method: API_METHODS.POST },
    createScorecardAttribute: { url: '/projects/:projectId/scorecard/attribute/', action: 'SAVE_PROJECT_SCORECARD_OPTION', method: API_METHODS.POST },
    createScorecardDetail: { url: '/projects/:projectId/scorecard/detail/', action: 'SAVE_PROJECT_SCORECARD_OPTION', method: API_METHODS.POST },
    updateScorecardAttribute: { url: '/projects/:projectId/scorecard/attribute/:attributeId/', action: 'SAVE_PROJECT_SCORECARD_OPTION', method: API_METHODS.POST },
    deleteScorecardAttribute: { url: '/projects/:projectId/scorecard/attribute/:attributeId/', action: 'REMOVE_PROJECT_SCORECARD_OPTION', method: API_METHODS.DELETE },

    // proforma
    fetchProforma: { url: '/scenarios/:scenarioId/proforma/', action: 'SAVE_PROFORMA', method: API_METHODS.GET },
    fetchProformaTopic: { url: '/proforma/:proformaId/topics/:topicId/', action: 'SAVE_TOPIC', method: API_METHODS.GET },
    fetchTopicItemSteps: { url: '/topic-definitions/:topicDefinitionId/steps/', action: 'SAVE_TOPIC_ITEM_STEPS', method: API_METHODS.GET },
    createProformaTopicCustomItem: { url: '/proforma/:proformaId/topics/:topicId/custom-item/', action: 'SAVE_TOPIC_ITEM', method: API_METHODS.POST },
    createProformaTopicData: { url: '/proforma-data/', action: 'SAVE_TOPIC_ITEM_DATA', method: API_METHODS.POST },
    updateProformaTopicData: { url: '/proforma-data/:topicDataId/', action: 'SAVE_TOPIC_ITEM_DATA', method: API_METHODS.POST },
    deleteCustomProformaEntry: { url: '/proforma/:proformaId/delete-custom/:itemId/', action: 'REMOVE_TOPIC_ITEM', method: API_METHODS.DELETE },
    resetProformaEntry: { url: '/proforma/:proformaId/reset/:itemId/', action: 'RESET_TOPIC_ITEM_DATA', method: API_METHODS.POST },
    fetchProformaTopicData: { url: '/proforma-data/:topicItemId/', action: 'SAVE_TOPIC_ITEM_DATA', method: API_METHODS.GET },
    fetchTopicItemCalculation: { url: '/proforma-calculations/topic-item/:topicItemId/', action: 'SAVE_ITEM_CALCULATION', method: API_METHODS.GET },
    fetchTopicCalculations: { url: '/proforma-calculations/topic/:topicId/', action: 'SAVE_TOPIC_CALCULATION', method: API_METHODS.GET },
    fetchAggregateCalculations: { url: '/proforma-calculations/aggregates/:projectId/', action: 'SAVE_ALL_TOPIC_CALCULATIONS', method: API_METHODS.GET },
    fetchProjectProformaData: { url: '/projects/:projectId/proforma-data/', action: 'SAVE_ALL_TOPIC_ITEM_DATA', method: API_METHODS.GET },

    fetchReportData: { url: '/projects/:projectId/report/', action: 'SAVE_REPORT_DATA', method: API_METHODS.GET },
    fetchTopicItemData: { url: '/projects/:projectId/topic-item-data/', action: 'SAVE_TOPIC_ITEM_REPORT_DATA', method: API_METHODS.GET },

    listConfigUnits: { url: '/config-units/', action: 'SAVE_CONFIG', method: API_METHODS.GET },
    fetchConfigUnits: { url: '/config-units/:unitRefs/', action: 'SAVE_CONFIG', method: API_METHODS.GET },

    // admin
    adminListUsers: { url: '/admin/users/', action: 'SAVE_ALL_USERS', method: API_METHODS.GET },
    adminListInvitations: { url: '/admin/invitations/', action: 'SAVE_ALL_INVITATIONS', method: API_METHODS.GET },
    adminListAccounts: { url: '/admin/accounts/', action: 'SAVE_ALL_ACCOUNTS', method: API_METHODS.GET },
    adminFetchAccount: { url: '/admin/accounts/:accountId/', action: 'SAVE_ACCOUNT', method: API_METHODS.GET },
    adminFetchAccountUsers: { url: '/admin/accounts/:accountId/users/', action: 'SAVE_ALL_USERS', method: API_METHODS.GET },
    adminFetchAccountInvitations: { url: '/admin/accounts/:accountId/invitations/', action: 'SAVE_ALL_INVITATIONS', method: API_METHODS.GET },
    adminInviteUser: { url: '/admin/invitations/', action: 'NULL_SAVE_ADMIN_INVITATION', method: API_METHODS.POST },
    adminFetchRoleTypes: { url: '/admin/roles/', action: 'SAVE_ALL_ROLES', method: API_METHODS.GET }
}

export const reduxRoutes = {
    addSiteToScenarioState: { action: 'SAVE_SITE_TO_SCENARIO_STATE' },
    removeSiteFromScenarioState: { action: 'REMOVE_SITE_FROM_SCENARIO_STATE' },
    removeAllSitesFromScenarioState: { action: 'REMOVE_ALL_SITES_FROM_SCENARIO_STATE' }
}

export const buildRoutePath = (route, params = {}) => {
    const toPath = pathToRegexp.compile(route)
    return toPath(params)
}

export const buildModalPath = (route, fullPagePath, params = {}) => {
    const modal = '/modal/'
    const modalIndex = fullPagePath.indexOf(modal)
    let pagePath = fullPagePath
    if (modalIndex !== -1) {
        pagePath = fullPagePath.substr(0, modalIndex + 1)
    }
    let modalRoute = route.substr(route.indexOf(modal) + 1)
    const toPath = pathToRegexp.compile(modalRoute)
    return `${pagePath}${toPath(params)}${window.location.search}`
}

export const buildCloseModalPath = (fullPagePath) => {
    const modal = '/modal/'
    const modalIndex = fullPagePath.indexOf(modal)
    let pagePath = fullPagePath
    if (modalIndex !== -1) {
        pagePath = fullPagePath.substr(0, modalIndex + 1)
    }
    return pagePath
}
