import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'

class PrivacyPolicy extends React.Component {
    render = () => {
        return (
            <div className="container legalize mb-4">
                <div className="text-center">
                    <h1>
                        Website Privacy Policy
                        VisProp Analytics<br />
                    </h1>
                </div>
                <p>Last Updated: July 8, 2021</p>
                <h2>Introduction and Scope</h2>
                <p>
                    VisProp Analytics LLC (<strong>"VisProp Analytics"</strong>, <strong>"Company"</strong> or <strong>"we"</strong>) recognizes your privacy is extremely
                    important and we are committed to protecting it through compliance with this Website Privacy Policy (this <strong>"Policy"</strong>).
                </p>
                <p>
                    This Policy covers the usage of www.VisPropAnalytics.com (our <strong>"Website"</strong>) and other company
                    products and services, accessed via the Website, including VisProp web-based applications. In addition,
                    this Policy describes the types of information we may collect from you or that you may provide when you visit the Website and our practices for using, maintaining, protecting, and disclosing that information.
                </p>
                <div>
                    <p className="mb-0">This policy applies to information we collect:</p>
                    <ul>
                        <li>On the Website.</li>
                        <li>On reports generated by our Website and accessed via links to our Website.</li>
                        <li>In email, text, and other electronic messages between you and this Website.</li>
                        <li>Our Company websites that directly link to this Policy when you click on "Privacy" in the Website footer.</li>
                    </ul>
                </div>
                <div>
                    <p className="mb-0">It does not apply to information collected by:</p>
                    <ul>
                        <li>Us offline or through any other means, including on any other website operated by Company; or </li>
                        <li>Any third party including through any application or content
                            (including advertising) that may link to or be accessible from or on the Website. The Company accepts no responsibility or liability in respect of third party websites.</li>
                    </ul>
                </div>
                <p>
                    Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it.
                    If you do not agree with our policies and practices, your choice is not to use our Website. <strong>By accessing or using this Website, you agree to this Policy.</strong>
                </p>

                <h2>Information We Collect About You and How We Collect It</h2>
                <div>
                    <p className="mb-0">We collect several types of information from and about users of our Website, including information:</p>
                    <ul>
                        <li>By which you may be personally identified, such as name, postal address, e-mail address, telephone number,
                            or any other identifier by which you may be contacted online or offline (<strong>"personal information"</strong>);</li>
                        <li>That is about you but individually does not identify you, such as your commercial real estate interests; and/or</li>
                        <li>About your internet connection, the equipment you use to access our Website, and usage details.</li>
                    </ul>
                </div>
                <div>
                    <p className="mb-0">We collect this information:</p>
                    <ul>
                        <li>Directly from you when you provide it to us.</li>
                        <li>Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</li>
                    </ul>
                </div>

                <h3>Information You Provide to Us</h3>
                <div>
                    <p className="mb-0">The information we collect on or through our Website may include:</p>
                    <ul>
                        <li>
                            Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website,
                            subscribing to our services, posting material, or requesting further services. We may also ask you for information when you report a problem with our Website.
                        </li>
                        <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
                        <li>Your responses to surveys that we might ask you to complete for research purposes or to improve our services.</li>
                    </ul>
                </div>
                <p>
                    You also may provide information to be published or displayed (hereinafter, <strong>"shared"</strong>) on reports generated by the Website,
                    or transmitted to other users of the Website (collectively, <strong>"User Contributions"</strong>).
                    Your User Contributions are shared and transmitted to others at your own risk. Although our security protocols require single-step authentication,
                    please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User Contributions.
                    Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.
                </p>

                <h3>Information We Collect Through Automatic Data Collection Technologies</h3>
                <div>
                    <p className="mb-0">As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>
                    <ul>
                        <li>Details of your visits to our Website, including traffic data, location data, and other communication data and the resources that you access and use on the Website.</li>
                        <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
                    </ul>
                </div>
                <div>
                    <p className="mb-0">The technologies we use for this automatic data collection may include:</p>
                    <ul>
                        <li><strong>Cookies (or browser cookies)</strong>. A cookie is a small file placed on the hard drive of your computer.
                        You may refuse to accept browser cookies by activating the appropriate setting on your browser.
                        However, if you select this setting you may be unable to access certain parts of our Website.
                        Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website. </li>
                        <li><strong>Flash Cookies</strong>. Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website.
                        Flash cookies are not managed by the same browser settings as are used for browser cookies. </li>
                        <li><strong>Web Beacons</strong>. Pages of our the Website and our e-mails may contain small electronic files known as web beacons
                        (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example,
                        to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain Website content and verifying system and server integrity). </li>
                    </ul>
                </div>

                <h2>How We Use Your Information</h2>
                <div>
                    <p className="mb-0">We use information that we collect about you or that you provide to us, including any personal information:</p>
                    <ul>
                        <li>To present our Website and its contents to you.</li>
                        <li>To provide the functionality of the services we provide to you, such as arranging access to your account and providing you with related user support services.</li>
                        <li>To fulfill any other purpose for which you provide it.</li>
                        <li>To provide you with notices about your account or subscription, including expiration and renewal notices.</li>
                        <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                        <li>To notify you about changes to our Website or any products or services we offer or provide through it.</li>
                        <li>To allow you to participate in interactive features on our Website.</li>
                        <li>To develop new products and services.</li>
                        <li>To improve and promote our business</li>
                        <li>To provide you with information, products, or services that you request from us.</li>
                        <li>To estimate audience size and gauge usage patterns.</li>
                        <li>In any other way we may describe when you provide the information.</li>
                        <li>For any other purpose with your consent.</li>
                    </ul>
                </div>

                <h2>Disclosure of Your Information</h2>
                <p>We may disclose aggregated and/or anonymized information about our users, without restriction.</p>
                <div>
                    <p className="mb-0">We may disclose personal information that we collect or you provide as described in this Policy:</p>
                    <ul>
                        <li>With your consent.</li>
                        <li>To fulfill the purpose for which you provide it. </li>
                        <li>To our subsidiaries and affiliates.</li>
                        <li>To contractors, service providers, and other third parties we use to support our business.</li>
                        <li>
                            To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
                            dissolution, or other sale or transfer of some or all of the Company’s assets, whether as a going
                            concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held
                            by the Company about our Website users is among the assets transferred.
                        </li>
                        <li>For any other purpose disclosed by us when you provide the information.</li>
                    </ul>
                </div>
                <div>
                    <p className="mb-0">We may also disclose your personal information:</p>
                    <ul>
                        <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                        <li>To enforce or apply our <Link to={routes.termsOfUse}>Terms of Service</Link> or Saas Agreement and other agreements, including for billing and collection purposes.</li>
                        <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others. </li>
                    </ul>
                </div>

                <h2>Accessing and Correcting Your Information</h2>
                <p>You can review and change your personal information by logging into the Website and visiting your account profile page.</p>
                <p>
                    You may also send us an email to request access to, correct or delete any personal information that you have provided to us.
                    It is possible that we may not be able to delete some or all of your personal information except by also deleting your user account.
                    We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
                </p>
                <p>
                    If you delete your User Contributions from the Website, copies of your User Contributions may remain viewable in cached and archived pages,
                    or might have been copied or stored by other Website users. Proper access and use of information provided on the Website,
                    including User Contributions, is governed by our <Link to={routes.termsOfUse}>Terms of Service</Link>.
                </p>

                <h2>Your California Privacy Rights</h2>
                <p>
                    If you are a California resident, California law may provide you with additional rights regarding our use of your personal information.
                    To learn more about your California privacy rights, visit <Link to="https://oag.ca.gov/privacy/ccpa" target="_blank">https://oag.ca.gov/privacy/ccpa</Link>.
                </p>

                <h2>Data Security</h2>
                <p>
                    We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.
                </p>
                <p>
                    The safety and security of your information also depends on you. Where we have given you (or where you have chosen)
                    a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
                </p>
                <p>
                    Unfortunately, the transmission of information via the internet is not completely secure.
                    We cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk.
                    We are not responsible for circumvention of any privacy settings or security measures contained on the Website.
                </p>

                <h2>Retention and Deletion</h2>
                <div>
                    <p className="mb-0">We retain personal information for as long as needed or permitted in light of the purpose(s) for which it was obtained and consistent with applicable law.
                        The criteria used to determine our retention periods include:</p>
                    <ul>
                        <li>The length of time we have an ongoing relationship with you and provide our services to you (for example, for as long as you have an account with us or keep using our Website);</li>
                        <li>Whether there is a legal obligation to which we are subject (for example, certain laws require us to keep records of your transactions for a certain period of time before we can delete them); or</li>
                        <li>Whether retention is advisable in light of our legal position (such as in regard to applicable statutes of limitations, litigation or regulatory investigations).</li>
                    </ul>
                </div>

                <h2>Jurisdiction and Cross-Border Transfer</h2>
                <p>
                    In providing our services or websites to EU citizens, we may need to transfer your personal information outside the European Economic Area ("EEA").
                    Your personal information may be stored and processed in any country where we have facilities or in which we engage service providers,
                    and by using the Website you understand that your information will be transferred to countries outside of your country of residence, including the United States,
                    which may have data protection rules that are different from those of your country.
                    In certain circumstances, courts, law enforcement agencies, regulatory agencies or security authorities in those other countries may be entitled to access your personal information.
                </p>
                <p>
                    Some of the non-EEA countries are recognized by the European Commission as providing an adequate level of data protection according to EEA standards
                    (the full list of these countries is available here:&nbsp;
                    <Link to="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en" target="_blank">
                        https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en
                    </Link>.
                </p>

                <h2>Changes to Our Policy</h2>
                <p>
                    It is our policy to post any changes we make to our Policy on this page. If we make material changes to how we treat our users’ personal information, we will notify you through a notice on the Website or via email.
                    The "Last Updated" legend at the top of this Policy indicates when this Policy was last revised.
                    You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this Policy to check for any changes.
                    Any changes will become effective when we post the revised Policy on the Website. Your continued use of this Website after we make changes is deemed to be acceptance of those changes.
                </p>

                <h2>Contact Information</h2>
                <p>
                    If you have any questions about this Policy, or if you wish to exercise your rights under applicable data protection laws, please feel free to contact us through our Website,
                    at: <a href="mailto:Info@VisPropAnalytics.com">Info@VisPropAnalytics.com</a>
                </p>
            </div>
        )
    }
}

export default PrivacyPolicy
