import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import AuthBg from '../shared/auth-bg'
import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

import { fetchPublicApiData } from '../../actions/api'
import { registerInvitationWithEmailAndPass } from '../../actions/auth'
import { apiRoutes, routes } from '../../constants/routes'

class AcceptInvitation extends React.Component {
    state = {

    }

    componentDidMount = () => {
        const { dispatch, match } = this.props
        dispatch(fetchPublicApiData(apiRoutes.getInvitation, { inviteCode: match.params.inviteCode }))
    }

    successFX = () => {

    }

    submitFX = (formData, formId) => {
        console.log(formData)
        this.props.dispatch(registerInvitationWithEmailAndPass(formData.email, formData.password, formId))
    }

    render = () => {
        const { invitation } = this.props
        let message = null
        if (!invitation) {
            message = 'Looking up invitation...'
        } else {
            if (!invitation.get('isValid')) {
                message = 'This invitation is no longer valid'
            }
            if (moment(invitation.get('expiration')).isBefore(moment())) {
                message = 'This invitation has expired'
            }
        }
        return (
            <>
                <AuthBg />
                <div className="auth-container">
                    <Link to={routes.index}>
                        <img src="/assets/img/brand/visprop-logo-white.png" width="260" alt="VisProp Logo" title="VisProp Logo" />
                    </Link>
                    <div className="content col-11 col-sm-4">
                        { message !== null
                            ? <div className="text-center">{message}</div>
                            : (
                                <>
                                    <h1>Register</h1>
                                    <p>Please use the form below to create login credentials for VisProp.</p>
                                    <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Login" buttonClass="black center">
                                        <InputElement
                                            type="email"
                                            default={invitation.get('email')}
                                            id="email"
                                            label="Email Address"
                                            placeholder="user@example.com"
                                            extraClass="flex-100"
                                            onChange={this.handleChange}
                                            required
                                            readOnly />
                                        <InputElement
                                            type="password"
                                            default=""
                                            id="password"
                                            label="Password"
                                            placeholder="A secret you only know..."
                                            extraClass="flex-100"
                                            onChange={this.handleChange}
                                            required />
                                    </SHSForm>
                                </>
                            )
                        }
                    </div>
                    <div className="links col-sm-4">
                        <p>
                            Have an account? <Link to={routes.login}>Login</Link>.
                        </p>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        invitation: state.get('invitations').find(stateInvite => stateInvite.get('code') === ownProps.match.params.inviteCode)
    }
}

export default connect(mapStateToProps)(AcceptInvitation)
