import immutable from 'immutable'

import {
    SAVE_AUTH_HANDLER, REMOVE_AUTH_HANDLER, KILL_STATE
} from '../constants/actions'

const INITIAL_STATE = immutable.fromJS({})

const authCodeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case SAVE_AUTH_HANDLER:
        return state
            .set('authCode', action.code)
            .set('authAction', action.action)
            .set('authTempEmail', action.email)
    case 'AUTH_HANDLER_ERROR':
        return state
            .set('hasError', true)
    case REMOVE_AUTH_HANDLER:
        return INITIAL_STATE
    case KILL_STATE:
        return immutable.fromJS({})
    default:
        return state
    }
}

export default authCodeReducer
