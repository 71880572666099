import immutable from 'immutable'

import * as helpers from './helpers'
import { KILL_STATE } from '../constants/actions'

const INITIAL_STATE = immutable.fromJS([])

const mapboxSiteReducer = (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    // let newState = state
    switch (action.type) {
    case 'SAVE_MAP_SITE':
        objectIndex = helpers.getObjectIndex(state, action.data.id)
        if (objectIndex === -1) {
            return helpers.addObject(state, action.data)
        }
        return helpers.updateObject(state, action.data, objectIndex)
    case 'REMOVE_MAP_SITE':
        return state.filter(project => project.get('id') !== action.data.id)
    case 'CLEAR_MAP_SITES':
        return INITIAL_STATE
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}

export default mapboxSiteReducer
