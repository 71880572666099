import immutable from 'immutable'

import * as helpers from './helpers'
import { CLEAR_FORM, UPSERT_FORM, KILL_STATE } from '../constants/actions'

const INITIAL_STATE = immutable.fromJS([])

const formReducer = (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    switch (action.type) {
    case UPSERT_FORM:
        objectIndex = helpers.getObjectIndex(state, action.id)
        if (objectIndex === -1) {
            return helpers.addObject(state, action)
        }
        return helpers.updateObject(state, action, objectIndex)
    case CLEAR_FORM:
        return state.filter(field => {
            return action.formId !== field.get('id')
        })
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}

export default formReducer
