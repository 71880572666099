import IMask from 'imask'
// import moment from 'moment'

export const integerMask = IMask.createMask({
    mask: Number,
    scale: 2,
    signed: false,
    thousandsSeparator: ',',
    normalizeZeros: true,
    radix: '.'
})

export const dollarMask = IMask.createMask({
    mask: '$amount',
    blocks: {
        amount: {
            mask: Number,
            scale: 2,
            signed: true,
            thousandsSeparator: ',',
            normalizeZeros: false,
            radix: '.'
        }
    }
})

export const phoneMask = IMask.createMask({
    mask: '(000) 000-0000'
})

export const postalMask = IMask.createMask({
    mask: '*****-****'
})

export const percentageMask = IMask.createMask({
    mask: [
        { mask: '' },
        {
            mask: 'num%',
            lazy: false,
            blocks: {
                num: {
                    mask: Number,
                    scale: 2,
                    min: -100,
                    max: 100,
                    radix: '.',
                    normalizeZeros: false,
                    mapToRadix: [',']
                }
            }
        }
    ]
})

export const displayPercentageMask = IMask.createMask({
    mask: [
        { mask: '' },
        {
            mask: 'num%',
            lazy: false,
            blocks: {
                num: {
                    mask: Number,
                    scale: 2,
                    radix: '.',
                    normalizeZeros: false
                }
            }
        }
    ]
})

// const momentDateFormat = 'MM/DD/YYYY'
//
// export const dateMask = IMask.createMask({
//     mask: Date,
//     pattern: momentDateFormat,
//     min: new Date(1970, 0, 1),
//     max: new Date(2100, 0, 1),
//     format: function (date) {
//         console.log('up here?')
//         return moment(date).format(momentDateFormat)
//     },
//     parse: function (str) {
//         console.log('in here?')
//         return moment(str, momentDateFormat)
//     },
//     blocks: {
//         MM: {
//             mask: IMask.MaskedRange,
//             from: 1,
//             to: 12
//         },
//         DD: {
//             mask: IMask.MaskedRange,
//             from: 1,
//             to: 31
//         },
//         YYYY: {
//             mask: IMask.MaskedRange,
//             from: 1970,
//             to: 2100
//         }
//     }
// })
