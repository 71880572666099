import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'

class TermsOfUse extends React.Component {
    render = () => {
        return (
            <div className="container legalize mb-4">
                <div className="text-center">
                    <h1>
                        Terms of Service<br />
                        VisProp Analytics
                    </h1>
                </div>
                <p>Last Updated: July 8, 2021</p>

                <p>
                    Thank you for selecting the Services offered by VisProp Analytics LLC, a Texas limited liability company (referred to as <strong>"Provider"</strong>, <strong>"we"</strong>, <strong>"our"</strong>, or <strong>"us"</strong>).
                    Please review this Terms of Service ("Agreement") thoroughly. This Agreement is a legal agreement between you (<strong>"Customer"</strong> or <strong>"you"</strong>) and VisProp Analytics.
                    Provider and Customer may be referred to herein collectively as the <strong>"Parties"</strong> or individually as a <strong>"Party."</strong>
                </p>
                <p>
                    By clicking "I Agree," indicating acceptance electronically, or by, accessing or using the Services, you agree to this Agreement.
                    If you do not agree to this Agreement, then you may not use the Services.
                </p>

                <h2>RECITALS</h2>
                <p>
                    WHEREAS, VisProp Analytics provides access to a software-as-a-service application that produces user defined interactive reports of financial analysis and qualitative
                    site assessments and other related services for commercial real estate site selection (collectively, the <strong>"Services"</strong>) to its customers via Provider's website; and
                </p>
                <p>
                    WHEREAS, Customer desires to access the Services, and VisProp Analytics desires to provide Customer access to the Services, subject to the terms and conditions of this Agreement.
                </p>

                <h2>GENERAL TERMS</h2>

                <h3>1. AGREEMENT</h3>
                <p>
                    This Agreement describes the mutual covenants, terms, and conditions governing your use of the Services.
                </p>

                <h3>2. DEFINITIONS</h3>
                <ol style={{ listStyle: 'lower-alpha' }}>
                    <li>
                        <strong>"Affiliate"</strong> means any entity which is directly or indirectly controlling, controlled by, or under common control with a Party to this Agreement.
                    </li>
                    <li>
                        <strong>"Authorized User"</strong> means Customer and, if applicable, Customer's employees, consultants, contractors, and agents (i) who are authorized by Customer to access and use the
                        Services under the rights granted to Customer pursuant to this Agreement and (ii) for whom access to the Services has been purchased hereunder.
                    </li>
                    <li>
                        <strong>"Customer Data"</strong> means text, audio, information, graphics, data, materials, and other content, in any form or medium, that is submitted, posted, or otherwise transmitted by
                        or on behalf of Customer or an Authorized User into such Customer's VisProp Account.
                    </li>
                    <li>
                        <strong>"Documentation"</strong> means Provider's user guides, online materials, and other documentation provided or made available by Provider relating to the Services to assist Customer,
                        as such documentation may be updated during the Term.
                    </li>
                    <li>
                        <strong>"Provider IP"</strong> means the Services, the Documentation, and any and all intellectual property provided to Customer or any Authorized User in connection with the foregoing.
                        For the avoidance of doubt, Provider IP does not include Customer Data.
                    </li>
                    <li>
                        <strong>"Services"</strong> has the meaning set forth in the Recitals.
                    </li>
                    <li>
                        <strong>"VisProp Account"</strong> means each Authorized User's password restricted account to access and use the Services.
                    </li>
                </ol>

                <h3>3. ACCESS AND USE</h3>
                <ol style={{ listStyle: 'lower-alpha' }}>
                    <li>
                        <u>Provision of Access</u>. Subject to the terms and conditions of this Agreement, Provider hereby grants Customer a non-exclusive, non-transferable (except in compliance with Section 21)f)
                        right to access and use the Services during the Term, solely for use by Authorized Users in accordance with the terms and conditions herein. Such use is limited to Customer's internal use.
                        Provider shall provide to Customer the necessary passwords and network links or connections to allow Customer to access the Services.
                    </li>
                    <li>
                        <u>Documentation License</u>. Subject to the terms and conditions contained in this Agreement, Provider hereby grants to Customer a non-exclusive, non-sub licensable, non-transferable
                        (except in compliance with Section 21)f) license to use the Documentation during the Term solely for Customer's internal business purposes in connection with its use of the Services.
                    </li>
                    <li>
                        <u>Authorized Users</u>. The total number of Authorized Users shall be determined by the Subscription Plan. The number of Authorized Users may be increased or decreased as expressly agreed to
                        in writing by the Parties and subject to any appropriate adjustment mutually agreed to in writing by the Parties of the Fees payable hereunder. Any sharing of VisProp Account user identification
                        and password is strictly prohibited.
                    </li>
                    <li>
                        <u>Use Restrictions</u>. Customer shall not use the Services for any purposes beyond the scope of the access granted in this Agreement. Customer shall not at any time, directly or indirectly,
                        and shall not permit any Authorized Users to: (i) copy, modify, or create derivative works of the Services or Documentation, in whole or in part; (ii) rent, lease, lend, sell, license,
                        sublicense, assign, distribute, publish, transfer, or otherwise make available the Services or Documentation; (iii) reverse engineer, disassemble, decompile, decode, adapt, or otherwise
                        attempt to derive or gain access to any software component of the Services, in whole or in part; (iv) remove any proprietary notices from the Services or Documentation; or (v) use the Services
                        or Documentation in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any person, or that violates any applicable law.
                        For the avoidance of doubt, Customer is expressly permitted to store, print, and copy all non-interactive final work products in portable document format created through the input of Customer Data
                        into the Services (the <strong>"Final Products"</strong>) without violating this Section.
                    </li>
                    <li>
                        <u>Monitoring</u>. Provider may, but has no obligation to, monitor access to or use of the Services to review or edit any Customer Data for the purpose of operating the Services,
                        to ensure compliance with this Agreement, and to comply with applicable law or other legal requirements. We may disclose any information necessary to satisfy our legal obligations,
                        protect Provider or its customers, or operate the Services properly. Provider, in its sole discretion, may remove, or refuse to remove, or disable any Customer Data, in whole or in part,
                        that is alleged to be, or that we consider to be unacceptable, undesirable, inappropriate, or in violation of this Agreement.
                    </li>
                    <li>
                        <u>Reservation of Rights</u>. Provider reserves all rights not expressly granted to Customer in this Agreement. Except for the limited rights and licenses expressly granted under this Agreement,
                        nothing in this Agreement grants, by implication, waiver, estoppel, or otherwise, to Customer or any third party any intellectual property rights or other right, title, or interest in or to the Provider IP.
                    </li>
                    <li>
                        <u>Suspension</u>. Notwithstanding anything to the contrary in this Agreement, Provider may temporarily suspend Customer's and any Authorized User's access to any portion or all of the Services if:
                        (i) Provider reasonably determines that (A) there is a threat or attack on any of the Provider IP; (B) Customer's or any Authorized User's use of the Provider IP disrupts or poses a security
                        risk to the Provider IP or to any other customer or vendor of Provider; (C) Customer, or any Authorized User, is using the Provider IP for fraudulent or illegal activities;
                        (D) subject to applicable law, Customer has ceased to continue its business in the ordinary course, made an assignment for the benefit of creditors or similar disposition of its assets,
                        or become the subject of any bankruptcy, reorganization, liquidation, dissolution, or similar proceeding; or (E) Provider's provision of the Services to Customer or any Authorized User is prohibited by applicable law;
                        (ii) any vendor of Provider has suspended or terminated Provider's access to or use of any third-party services or products required to enable Customer to access the Services; or (iii) in accordance with Section 7)a)
                        (any such suspension described in sub clause (i), or (ii), a <strong>"Service Suspension"</strong>). Provider shall use commercially reasonable efforts to provide written notice of any Service Suspension to
                        Customer and to provide updates regarding resumption of access to the Services following any Service Suspension. Provider shall use commercially reasonable efforts to resume providing access to the Services as soon as
                        reasonably possible after the event giving rise to the Service Suspension is cured. Provider will have no liability for any damage, liabilities, losses (including any loss of data or profits), or any other consequences
                        that Customer or any Authorized User may incur as a result of a Service Suspension.
                    </li>
                </ol>

                <h3>4. PRIVACY</h3>
                <p>
                    You acknowledge that Provider will process your personal information and Customer Data as described in our <Link to={routes.privacyPolicy}>Privacy Statement</Link> when you use our services.
                </p>

                <h3>5. CUSTOMER RESPONSIBILITIES</h3>
                <ol style={{ listStyle: 'lower-alpha' }}>
                    <li>
                        <u>General</u>. Customer is responsible and liable for all uses of the Services and Documentation resulting from access provided by Customer, directly or indirectly,
                        whether such access or use is permitted by or in violation of this Agreement. Without limiting the generality of the foregoing, Customer is responsible
                        for all acts and omissions of Affiliates and Authorized Users, and any act or omission by an Affiliate or Authorized User that would constitute a breach
                        of this Agreement if taken by Customer will be deemed a breach of this Agreement by Customer. Customer shall use reasonable efforts to make all Authorized
                        Users aware of this Agreement's provisions as applicable to such Authorized User's use of the Services, and shall cause Authorized Users to comply with such provisions.
                    </li>
                </ol>

                <h3>6. MAINTENANCE</h3>
                <ol style={{ listStyle: 'lower-alpha' }}>
                    <li>
                        <u>Scheduled Maintenance</u>. Provider shall conduct all scheduled maintenance from 12:00am US CT to 5:00am US CT (the <strong>"Scheduled Maintenance Window"</strong>);
                        provided that Provider may change such Scheduled Maintenance Window to a different period with advance notice to Customer.
                    </li>
                    <li>
                        <u>Emergency Maintenance</u>. Provider reserves the right to perform any required emergency maintenance work outside of the Scheduled Maintenance Window.
                        Provider shall use commercially reasonable efforts to notify Customer before commencing any emergency maintenance outside of the Scheduled Maintenance Window.
                    </li>
                </ol>

                <h3>7. PAYMENT</h3>
                <ol style={{ listStyle: 'lower-alpha' }}>
                    <li>
                        <u>Payment</u>. For Services offered on a subscription basis, the following terms apply, unless Provider notifies you otherwise in writing.
                        This Agreement also incorporates by reference and includes payment terms provided to you on the website and in the Order Confirmation for the Services.
                        Customer shall pay Provider the fees for the Term as set forth in the Order Confirmation without offset or deduction.
                        Customer shall make all payments hereunder in US dollars on or before the due date set forth in the Order Confirmation and within thirty (30)
                        days of the date of the applicable electronic invoice.  Unless timely provided with a valid certificate of exemption or other evidence that
                        items are not taxable, Provider will invoice you for all applicable taxes including, but not limited to, VAT, GST, sales tax, consumption tax and service tax.
                    </li>
                    <li>
                        <u>Invoices</u>. Provider shall invoice Customer for all Fees on the day of the calendar month of the Term which corresponds with the end of Customer's Trial Period. Customer shall pay all undisputed
                        invoices within thirty (30) days after Customer receives the invoice. Except as expressly provided otherwise, Fees are non-refundable.
                        If Customer fails to make any payment when due, without limiting Provider's other rights and remedies: (i) Customer shall reimburse Provider for
                        all reasonable costs incurred by Provider in collecting any late payments or interest, including attorneys' fees, court costs, and collection agency fees;
                        and (ii) if such failure continues for five (5) days or more following delivery of Provider's written notice thereof, Provider may suspend Customer's and
                        its Authorized Users' access to any portion or all of the Services until such amounts are paid in full.
                    </li>
                    <li>
                        <u>Renewal</u>. Provider will automatically renew your Services at the then-current rates, unless the Services subscription is cancelled or terminated under
                        this Agreement.
                    </li>
                    <li>
                        <u>Cancellation</u>. Customer may cancel their subscription at any time by giving a seven (7) day notice. Fees are non-refundable.
                    </li>
                </ol>

                <h3>8. CONFIDENTIAL INFORMATION</h3>
                <p>
                    From time to time during the Term, either Party may disclose or make available to the other Party information about its business affairs, products, confidential
                    intellectual property, trade secrets, third-party confidential information, and other sensitive or proprietary information, whether orally or in written, electronic,
                    or other form or media/in written or electronic form or media, and whether or not marked, designated, or otherwise identified as "confidential" (collectively, <strong>"Confidential Information"</strong>).
                    Confidential Information does not include information that, at the time of disclosure is: (a) in the public domain; (b) known
                    to the receiving Party at the time of disclosure; (c) rightfully obtained by the receiving Party on a non-confidential basis from a third party; or (d) independently
                    developed by the receiving Party. <strong>The receiving Party shall not disclose the disclosing Party's Confidential Information to any person or entity, except to the receiving
                    Party's employees who have a need to know the Confidential Information for the receiving Party to exercise its rights or perform its obligations hereunder.
                    </strong> Notwithstanding the foregoing, each Party may disclose Confidential Information to the limited extent required (i) in order to comply with the order of a court or
                    other governmental body, or as otherwise necessary to comply with applicable law, provided that the Party making the disclosure pursuant to the order shall first have
                    given written notice to the other Party and made a reasonable effort to obtain a protective order; or (ii) to establish a Party's rights under this Agreement,
                    including to make required court filings. Each Party's obligations of non-disclosure with regard to Confidential Information are effective as of the Effective Date
                    and will expire two (2) years from the date first disclosed to the receiving Party; provided, however, with respect to any Confidential Information that constitutes a
                    trade secret (as determined under applicable law), such obligations of non-disclosure will survive the termination or expiration of this Agreement for as long as such
                    Confidential Information remains subject to trade secret protection under applicable law.
                </p>

                <h3>9. INTELLECTUAL PROPERTY OWNERSHIP; FEEDBACK</h3>
                <ol style={{ listStyle: 'lower-alpha' }}>
                    <li>
                        <u>Provider IP</u>. Customer acknowledges that, as between Customer and Provider, Provider owns all right, title, and interest, including all intellectual property rights, in and to the Provider IP.
                    </li>
                    <li>
                        <u>Customer Data</u>. Provider acknowledges that, as between Provider and Customer, Customer owns all right, title, and interest, including all intellectual property rights,
                        in and to the Customer Data. Customer hereby grants to Provider a non-exclusive, royalty-free, worldwide license to reproduce, distribute, and otherwise use and display
                        the Customer Data and perform all acts with respect to the Customer Data as may be necessary for Provider to provide the Services to Customer.
                    </li>
                    <li>
                        <u>Feedback</u>. If Customer or any of its employees or contractors sends or transmits any communications or materials to Provider by mail, email, telephone, or otherwise,
                        suggesting or recommending changes to the Provider IP, including without limitation, new features or functionality relating thereto, or any comments, questions, suggestions,
                        or the like (<strong>"Feedback"</strong>), Provider is free to use such Feedback irrespective of any other obligation or limitation between the Parties governing such Feedback.
                        Customer hereby assigns to Provider on Customer's behalf, and on behalf of its employees, contractors and/or agents, all right, title, and interest in, and Provider
                        is free to use, without any attribution or compensation to any party, any ideas, know-how, concepts, techniques, or other intellectual property rights contained in
                        the Feedback, for any purpose whatsoever, although Provider is not required to use any Feedback.
                    </li>
                </ol>

                <h3>10. WARRANTY DISCLAIMER</h3>
                <ol style={{ listStyle: 'lower-alpha' }}>
                    <li>
                        THE PROVIDER IP IS PROVIDED "AS IS" AND PROVIDER HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. PROVIDER SPECIFICALLY DISCLAIMS ALL
                        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE
                        PRACTICE. PROVIDER MAKES NO WARRANTY OF ANY KIND THAT THE PROVIDER IP, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET CUSTOMER'S OR ANY OTHER PERSON'S REQUIREMENTS,
                        OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE,
                        FREE OF HARMFUL CODE, OR ERROR FREE.
                    </li>
                </ol>

                <h3>11. INDEMNIFICATION</h3>
                <ol style={{ listStyle: 'lower-alpha' }}>
                    <li>
                        <u>Provider Information</u>
                        <ol>
                            <li>
                                Provider shall indemnify, defend, and hold harmless Customer from and against any and all losses, damages, liabilities, costs (including reasonable attorneys' fees)
                                (<strong>"Losses"</strong>) incurred by Customer resulting from any third-party claim, suit, action, or proceeding (<strong>"Third-Party Claim"</strong>) that the Services, or any use of the Services
                                in accordance with this Agreement, infringes or misappropriates such third party's US intellectual property rights, provided that Customer promptly notifies Provider
                                in writing of the claim, cooperates with Provider, and allows Provider sole authority to control the defense and settlement of such claim.
                            </li>
                            <li>
                                If such a claim is made or appears possible, Customer agrees to permit Provider, at Provider's sole discretion, to (A) modify or replace the Services, or component
                                or part thereof, to make it non-infringing, or (B) obtain the right for Customer to continue use. If Provider determines that neither alternative is reasonably available,
                                Provider may terminate this Agreement, in its entirety or with respect to the affected component or part, effective immediately on written notice to Customer.
                            </li>
                            <li>
                                This Section 11)a) will not apply to the extent that the alleged infringement arises from: (A) use of the Services in combination with data, software, hardware,
                                equipment, or technology not provided by Provider or authorized by Provider in writing; (B) modifications to the Services not made by Provider; or (C) Customer Data.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <u>Customer Indemnification</u>. Customer shall indemnify, hold harmless, and, at Provider's option, defend Provider from and against any Losses resulting from any Third-Party Claim
                        that the Customer Data, or any use of the Customer Data in accordance with this Agreement, infringes or misappropriates such third party's US intellectual property rights,
                        provided that Customer may not settle any Third-Party Claim against Provider unless Provider consents to such settlement, and further provided that Provider will have the right,
                        at its option, to defend itself against any such Third-Party Claim or to participate in the defense thereof by counsel of its own choice.
                    </li>
                    <li>
                        <u>Sole Remedy</u>. THIS SECTION 11) SETS FORTH CUSTOMER'S SOLE REMEDIES AND PROVIDER'S SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL, THREATENED, OR ALLEGED CLAIMS THAT THE
                        SERVICES INFRINGE, MISAPPROPRIATE, OR OTHERWISE VIOLATE ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY. IN NO EVENT WILL PROVIDER'S LIABILITY UNDER THIS SECTION 9
                        EXCEED THE TOTAL AMOUNTS PAID TO PROVIDER UNDER THIS AGREEMENT DURING THE TERM.
                    </li>
                </ol>

                <h3>12. LIMITATIONS OF LIABILITY</h3>
                <p>
                    IN NO EVENT WILL PROVIDER BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
                    STRICT LIABILITY, AND OTHERWISE, FOR ANY: (a) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES; (b) INCREASED COSTS, DIMINUTION IN VALUE
                    OR LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c) LOSS OF GOODWILL OR REPUTATION; (d) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY, OR RECOVERY OF ANY DATA, OR BREACH
                    OF DATA OR SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR SERVICES, IN EACH CASE REGARDLESS OF WHETHER PROVIDER WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES
                    OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. IN NO EVENT WILL PROVIDER'S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE
                    THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE EXCEED THE TOTAL AMOUNTS PAID TO PROVIDER UNDER THIS AGREEMENT DURING THE TERM.
                </p>

                <h3>13. TERM; RENEWALS; AND TERMINATION</h3>
                <ol style={{ listStyle: 'lower-alpha' }}>
                    <li>
                        <u>Term</u>. The initial term of this Agreement begins on the Effective Date in the Order Confirmation which corresponds to the final day of the Trial Period. Depending on the
                        subscription plan selected by the Customer, the term shall either be one year or one month from such date (the <strong>"Initial Term"</strong>). This Agreement will automatically
                        renew unless earlier terminated pursuant to this Agreement's express provisions or either Party gives the other Party notice
                        of cancellation or non-renewal at least seven (7) days prior to the expiration of the then-current term (each a <strong>"Renewal Term"</strong> and together with the Initial Term, the <strong>"Term"</strong>).
                    </li>
                    <li>
                        <u>Termination</u>. In addition to any other express termination right set forth in this Agreement:
                        <ol>
                            <li>
                                Provider may terminate this Agreement, effective on written notice to Customer, if Customer: (A) fails to pay any amount when due hereunder, and such failure continues
                                more than five (5) days after Provider's delivery of written notice thereof; or (B) breaches any of its obligations under Section 3)d) or Section 8);
                            </li>
                            <li>
                                either Party may terminate this Agreement, effective on written notice to the other Party, if the other Party breaches this Agreement, and such breach:
                                (A) is incapable of cure; or (B) being capable of cure, remains uncured thirty (30) days after the non-breaching Party provides the breaching Party with written notice
                                of such breach; or
                            </li>
                            <li>
                                either Party may terminate this Agreement, effective immediately upon written notice to the other Party, if the other Party: (A) becomes insolvent or is generally
                                unable to pay, or fails to pay, its debts as they become due; (B) files or has filed against it, a petition for voluntary or involuntary bankruptcy or otherwise
                                becomes subject, voluntarily or involuntarily, to any proceeding under any domestic or foreign bankruptcy or insolvency law; (C) makes or seeks to make a general
                                assignment for the benefit of its creditors; or (D) applies for or has appointed a receiver, trustee, custodian, or similar agent appointed by order of any court of
                                competent jurisdiction to take charge of or sell any material portion of its property or business.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <u>Effect of Expiration or Termination</u>. Upon expiration or earlier termination of this Agreement, Customer shall immediately discontinue use of the Provider IP and,
                        without limiting Customer's obligations under Section 8), Customer shall delete, destroy, or return all copies of the Provider IP, except for Final Products, and
                        if requested by Provider, certify in writing to the Provider that the Provider IP has been deleted or destroyed. No expiration or termination will affect Customer's
                        obligation to pay all Fees that may have become due before such expiration or termination or entitle Customer to any refund.
                    </li>
                    <li>
                        <u>Survival</u>. This Section 19(d) and Sections 1), a), 8), 9), 8a), 11), 12), and 14) survive any termination or expiration of this Agreement. No other provisions of this Agreement
                        survive the expiration or earlier termination of this Agreement.
                    </li>
                </ol>

                <h3>14. AMENDMENT AND MODIFICATION</h3>
                <p>
                    Provider reserves the right to modify this Agreement from time to time in their sole discretion. Amendments or modifications will be effective when posted through the Services or on
                    the website or when Provider notifies Customer by other means. Continued use of the Services indicates Customer agreement to the amendments or modifications.
                </p>

                <h3>15. MISCELLANEOUS</h3>
                <ol style={{ listStyle: 'lower-alpha' }}>
                    <li>
                        <u>Entire Agreement</u>. This Agreement, together with any other documents incorporated herein by reference and all related Exhibits, constitutes the sole and entire agreement of
                        the Parties with respect to the subject matter of this Agreement and supersedes all prior and contemporaneous understandings, agreements, and representations and warranties,
                        both written and oral, with respect to such subject matter.
                    </li>
                    <li>
                        <u>Notices</u>. All notices, requests, consents, claims, demands, waivers, and other communications hereunder (each, a <strong>"Notice"</strong>) must be in writing.
                    </li>
                    <li>
                        <u>Force Majeure</u>. In no event shall Provider be liable to Customer, or be deemed to have breached this Agreement, for any failure or delay in performing its obligations under
                        this Agreement, if and to the extent such failure or delay is caused by any circumstances beyond Provider's reasonable control, including but not limited to acts of God,
                        flood, fire, earthquake, explosion, war, terrorism, invasion, riot or other civil unrest, strikes, labor stoppages or slowdowns or other industrial disturbances, or passage
                        of law or any action taken by a governmental or public authority, including imposing an embargo.
                    </li>
                    <li>
                        <u>Severability</u>. If any provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability will not affect
                        any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction. Upon such determination that any term
                        or other provision is invalid, illegal, or unenforceable, the Parties shall negotiate in good faith to modify this Agreement so as to effect their original intent as closely
                        as possible in a mutually acceptable manner in order that the transactions contemplated hereby be consummated as originally contemplated to the greatest extent possible.
                    </li>
                    <li>
                        <u>Governing Law; Submission to Jurisdiction</u>. This Agreement is governed by and construed in accordance with the internal laws of the State of Texas without giving effect to
                        any choice or conflict of law provision or rule that would require or permit the application of the laws of any jurisdiction other than those of the State of Texas.
                        Any legal suit, action, or proceeding arising out of or related to this Agreement or the licenses granted hereunder will be instituted exclusively in the federal courts
                        of the United States or the courts of the State of Texas in each case located in the city of Austin and County of Travis, and each Party irrevocably submits to the exclusive
                        jurisdiction of such courts in any such suit, action, or proceeding.
                    </li>
                    <li>
                        <u>Assignment</u>. Customer may not assign any of its rights or delegate any of its obligations hereunder, in each case whether voluntarily, involuntarily, by operation of law or
                        otherwise, without the prior written consent of Provider. Any purported assignment or delegation in violation of this Section will be null and void. No assignment or
                        delegation will relieve the assigning or delegating Party of any of its obligations hereunder. This Agreement is binding upon and inures to the benefit of the Parties
                        and their respective permitted successors and assigns.
                    </li>
                    <li>
                        <u>Export Regulation</u>. Customer shall comply with all applicable federal laws, regulations, and rules, and complete all required undertakings (including obtaining any necessary
                        export license or other governmental approval), that prohibit or restrict the export or re-export of the Services or any Customer Data outside the US.
                    </li>
                    <li>
                        <u>Equitable Relief</u>. Each Party acknowledges and agrees that a breach or threatened breach by such Party of any of its obligations under Section 8) or, in the case of Customer,
                        Section 3)d), would cause the other Party irreparable harm for which monetary damages would not be an adequate remedy and agrees that, in the event of such breach or threatened
                        breach, the other Party will be entitled to equitable relief, including a restraining order, an injunction, specific performance, and any other relief that may be available
                        from any court, without any requirement to post a bond or other security, or to prove actual damages or that monetary damages are not an adequate remedy.
                        Such remedies are not exclusive and are in addition to all other remedies that may be available at law, in equity, or otherwise.
                    </li>
                    <li>
                        <u>Counterparts</u>. This Agreement may be executed in counterparts, each of which is deemed an original, but all of which together are deemed to be one and the same agreement.
                    </li>
                </ol>

                <h3>16. ADDITIONAL TERMS</h3>
                <ol style={{ listStyle: 'lower-alpha' }}>
                    <li>
                        Provider does not give professional advice or warrant the accuracy of the information generated in VisProp Reports. Provider does not provide financial, accounting,
                        tax, legal, or real estate brokerage services or advice.
                    </li>
                    <li>
                        <u>Other Services</u>. Provider may offer other services, products, or promotions to Customer from time to time. Additional terms and conditions and fees may apply.
                    </li>
                    <li>
                        Communications. Customer agrees that Provider may be required by law to send communications about the Services or third party products and that Provider may send
                        these communications to Customer via email or by posting them on our website.
                    </li>
                </ol>
            </div>

        )
    }
}

export default TermsOfUse
