import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import AuthBg from '../shared/auth-bg'

import { routes } from '../../constants/routes'

class NeedSubscriptionSeat extends React.Component {
    state = {}

    componentDidMount = () => {
        if (this.props.seat && this.props.seat.get('seatId')) {
            this.props.history.push(routes.appIndex)
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!prevProps.seat && this.props.seat && this.props.seat.get('seatId')) {
            this.props.history.push(routes.appIndex)
        }
    }

    render = () => {
        return (
            <>
                <AuthBg />
                <div className="auth-container">
                    <Link to={routes.index}>
                        <img src="/assets/img/brand/visprop-logo-white.png" width="260" alt="VisProp Logo" title="VisProp Logo" />
                    </Link>
                    <div className="content col-11 col-sm-4">
                        <h1>Missing Seat Subscription</h1>
                        <p>
                            You are not assigned to a seat in your account's subscription.
                            Please contact your primary account holder to get an assigned seat.
                        </p>
                        <p>
                            If you think you received this in error, please contact <a href="mailto:support@vispropanalytics.com">support</a>.
                        </p>
                        <Link to={routes.logout}>Logout</Link>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        seat: state.get('seat')
    }
}

export default connect(mapStateToProps)(NeedSubscriptionSeat)
