import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import AuthBg from '../shared/auth-bg'
import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

import { generatePasswordResetLink } from '../../actions/auth'
import { routes } from '../../constants/routes'

class PasswordHelp extends React.Component {
    state = {
        linkSent: false
    }

    renderHelmet = () => {
        return (
            <Helmet>
                <title>Login Help for VisProp Analytics</title>
                <meta name="description" content="Having trouble logging into your account. Use this page to start the password reset process. " />
            </Helmet>
        )
    }

    successFX = () => {
        this.setState({ linkSent: true })
    }

    submitFX = (formData, formId) => {
        this.props.dispatch(generatePasswordResetLink(formData.email, formId))
    }

    render = () => {
        return (
            <>
                {this.renderHelmet()}
                <AuthBg />
                <div className="auth-container">
                    <Link to={routes.index}>
                        <img src="/assets/img/brand/visprop-logo-white.png" width="260" alt="VisProp Logo" title="VisProp Logo" />
                    </Link>
                    <div className="content col-11 col-sm-4">
                        <h1>Password Help</h1>
                        <p>Get help accessing your account if you have forgotten your password.</p>
                        { this.state.linkSent
                            ? <p>Check your email, we sent you a link to help reset your password.</p>
                            : (
                                <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Get Password Help" buttonClass="black center">
                                    <InputElement
                                        type="email"
                                        default=""
                                        id="email"
                                        label="Email Address"
                                        placeholder="user@example.com"
                                        extraClass="flex-100"
                                        required />
                                </SHSForm>
                            )
                        }
                    </div>
                    <div className="links col-sm-4">
                        <p>
                            <Link to={routes.login}>Return to login</Link>
                        </p>
                        <p>
                            Don't have an account? <Link to={routes.register}>Register</Link>.
                        </p>
                    </div>
                </div>
            </>
        )
    }
}

export default connect()(PasswordHelp)
