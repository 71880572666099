import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import AuthBg from '../shared/auth-bg'
import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

import { callApi } from '../../actions/api'
import { routes, apiRoutes } from '../../constants/routes'

import * as MASKS from '../utils/input-masks'

class CreateUser extends React.Component {
    state = {

    }

    successFX = () => {
        this.props.history.push(routes.router)
    }

    submitFX = (formData, formId) => {
        this.props.dispatch(callApi(apiRoutes.createUser, formData, formId))
    }

    render = () => {
        return (
            <>
                <AuthBg />
                <div className="auth-container">
                    <Link to={routes.index}>
                        <img src="/assets/img/brand/visprop-logo-white.png" width="260" alt="VisProp Logo" title="VisProp Logo" />
                    </Link>
                    <div className="content col-11 col-sm-4">
                        <h1>Personal Information</h1>
                        <p>Please fill in the fields below to complete your user profile.</p>
                        <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Submit">
                            <InputElement
                                type="text"
                                default=""
                                id="firstName"
                                label="First Name"
                                placeholder="Ty"
                                extraClass="flex-50"
                                required />
                            <InputElement
                                type="text"
                                default=""
                                id="lastName"
                                label="Last Name"
                                placeholder="Cobb"
                                extraClass="flex-50"
                                required />
                            <InputElement
                                type="phone"
                                default=""
                                id="phone"
                                label="Phone Number"
                                isFormatted={true}
                                formatMask={MASKS.phoneMask}
                                extraClass="flex-50"
                                placeholder="(123) 456-7890"
                                required />
                            <InputElement
                                type="text"
                                default=""
                                id="postal"
                                label="Zip Code"
                                isFormatted={true}
                                formatMask={MASKS.postalMask}
                                extraClass="flex-50"
                                placeholder="90210"
                                required />
                            <InputElement
                                type="text"
                                default=""
                                id="company"
                                label="Company"
                                extraClass="flex-50"
                                placeholder="Acme Real Estate" />
                            <InputElement
                                type="text"
                                default=""
                                id="title"
                                label="Title"
                                extraClass="flex-50"
                                placeholder="Tycoon" />
                            <InputElement
                                type="checkbox"
                                id="acceptedTerms"
                                default=""
                                label={<>I accept the VisProp <Link to={routes.termsOfUse} target="_blank">Terms of Service</Link> and <Link to={routes.privacyPolicy} target="_blank">Privacy Policy</Link></>}
                                required />
                        </SHSForm>
                    </div>
                </div>
            </>
        )
    }
}

export default connect()(CreateUser)
