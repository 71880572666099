import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { routes } from '../../constants/routes'

export function AppRoute({ component: Component, auth, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => !auth.isEmpty() && !auth.get('isAnonymous')
                ? <Component auth={auth} {...props} />
                : <Redirect to={{ pathname: routes.login, state: { from: props.location } }} />}
        />
    )
}

// TODO: make the auth check more restrictive
export function AdminRoute({ component: Component, auth, role, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => !role.isEmpty() && role.get('name') === 'Super Admin'
                ? <Component auth={auth} {...props} />
                : <Redirect to={{ pathname: routes.login, state: { from: props.location } }} />}
        />
    )
}
