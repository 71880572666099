import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

// import moment from 'moment'
// import startCase from 'lodash/startCase'

import SHSForm from '../../../../utils/shs-form'
import InputElement from '../../../../utils/input'

import { callApi, fetchConfigData, apiRemoveAction } from '../../../../../actions/api'
import { apiResourceEndpoint, routes } from '../../../../../constants/routes'

import { formatCurrency } from '../../../../utils/formatters'

const ReviewOrder = ({ dispatch, steps, stepIndex, onAdvanceStep, productId, priceId, paymentMethod, nameOnCard, rate, localAppState, onSetPromo, couponCode, productName }) => {
    const [ totals, setTotals ] = useState(null)
    const [ showPromo, setShowPromo ] = useState(false)
    const [ promoCode, setPromoCode ] = useState(null)
    const [ isFetching, setIsFetching ] = useState(false)
    const selfIndex = steps.indexOf('REVIEW')

    useEffect(() => {
        fetchTotals()
    }, [productId])

    const successFX = () => {
        dispatch(apiRemoveAction({
            id: 'REGISTER',
            _key: 'LOCAL_APP_STATE'
        }))
        onAdvanceStep()
    }

    const submitFX = (formData, formId) => {
        let promo = null
        let taxId = null
        if (totals && totals.promoIsValid && promoCode) {
            promo = promoCode
        }
        if (totals && totals.taxId) {
            taxId = totals.taxId
        }
        const postData = {
            quantity: 1,
            priceId,
            paymentToken: paymentMethod ? paymentMethod.id : null,
            nameOnCard,
            rate,
            couponCode: promo,
            registerType: localAppState.get('registerType') || null,
            taxId
        }
        dispatch(callApi(apiResourceEndpoint('subscription', 'CREATE'), postData, formId))
    }

    const successPromoFX = () => {
        setShowPromo(false)
    }

    const submitPromoFX = (formData, formId) => {
        setPromoCode(formData.promo)
        setShowPromo(false)
        onSetPromo(formData.promo)
        fetchTotals(formData.promo)
    }

    const fetchTotals = async (promo = null) => {
        let totalParams = {
            price_id: priceId,
            quantity: 1
        }
        if (promo) {
            totalParams.promo = promo
        } else if (promoCode) {
            totalParams.promo = promoCode
        }
        let totalData = null
        setIsFetching(true)
        totalData = await fetchConfigData(apiResourceEndpoint('stripe/total', 'LIST').url, totalParams)
        setTotals(totalData)
        setIsFetching(false)
    }

    const renderTotals = () => {
        const subtotal = rate / 100
        let promoTotal = 0
        if (totals && totals.promoIsValid) {
            promoTotal = -1 * subtotal * (totals.promoValue / 100)
        }
        let taxes = 0
        if (totals && totals.taxRate) {
            taxes = (subtotal + promoTotal) * (totals.taxRate / 100)
        }
        const total = subtotal + promoTotal + taxes


        if (!totals) {
            return null
        }
        return (
            <table className="table mb-0">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Rate</th>
                        <th className="text-right">Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    {/* <tr>
                        <td>Free Trial ({moment().format('MMMM D, Y')} - {moment().add(14, 'days').format('MMMM D, Y')})</td>
                        <td>$0.00</td>
                        <td className="text-right">$0.00</td>
                    </tr> */}
                    <tr>
                        <td>{productName}</td>
                        <td>{formatCurrency(rate / 100)}</td>
                        <td className="text-right">{formatCurrency(subtotal, 2, 2)}</td>
                    </tr>
                    { totals && totals.promoIsValid &&
                        <tr>
                            <td>{totals.promoDescription}</td>
                            <td>{promoCode}</td>
                            <td className="text-right">{formatCurrency(promoTotal)}</td>
                        </tr>
                    }
                    <tr>
                        <td>Sales Tax</td>
                        <td>{totals.taxRate}%</td>
                        <td className="text-right">{formatCurrency(taxes, 2, 2)}</td>
                    </tr>
                    <tr>
                        <td>Total</td>
                        <td>&nbsp;</td>
                        <td className="text-right">{formatCurrency(total, 2, 2)}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    const renderPromoForm = () => {
        if (!showPromo) {
            return null
        }
        return (
            <SHSForm
                extraClass="inline px-2"
                formClass="inline"
                showCancel={true}
                buttonLabel="Apply"
                cancelAction={() => setShowPromo(false)}
                submitFX={submitPromoFX}
                successFX={successPromoFX}>
                <InputElement
                    type="text"
                    id="promo"
                    default={couponCode}
                    label="Group Code"
                    placeholder="Enter a Code" />
            </SHSForm>
        )
    }

    const renderPromoDisplay = () => {
        if (showPromo || isFetching) {
            return null
        }
        return (
            <>
                { promoCode
                    ? <div className="text-right px-2 mb-3">
                        <span>Code Entered: </span>
                        <span> {promoCode} </span>
                        { totals && !totals.promoIsValid &&
                            <span> (INVALID CODE) </span>
                        }
                        <span className="clickable text-blue" onClick={() => setShowPromo(!showPromo)}> Change Code </span>
                    </div>
                    : <div className="text-right px-2 mb-3">
                        &nbsp;
                        {/* <span className="clickable text-blue" onClick={() => setShowPromo(!showPromo)}>
                            Have a group code?
                        </span> */}
                    </div>
                }
            </>
        )
    }

    const renderActiveState = () => {
        if (selfIndex !== stepIndex) {
            return null
        }

        return (
            <>
                {renderTotals()}
                {renderPromoForm()}
                {renderPromoDisplay()}
                <SHSForm submitFX={submitFX} successFX={successFX} buttonLabel="Purchase">
                    <InputElement
                        type="checkbox"
                        id="agreeToSaas"
                        label={<span>I agree to purchase this subscription and to the terms set forth in the <Link to={routes.termsOfUse} target="_blank">SaaS agreement</Link>.</span>}
                        required />
                </SHSForm>
            </>
        )
    }

    const renderPastState = () => {
        if (selfIndex >= stepIndex) {
            return null
        }
        return (
            <>&nbsp;</>
        )
    }

    const renderFutureState = () => {
        if (selfIndex <= stepIndex) {
            return null
        }
        return (
            <>&nbsp;</>
        )
    }

    const renderSectionHeader = (clickable = false) => {
        return (
            <>
                <h2>Step 3: Review Subscription</h2>
                { selfIndex === stepIndex &&
                    <p>
                        The following charges will be applied to your credit card.
                        You can modify or cancel your plan at any time.
                    </p>
                }
            </>
        )
    }

    if (stepIndex === steps.length - 1) {
        return null
    }
    return (
        <div className="content p-4">
            {renderSectionHeader()}
            {renderActiveState()}
            {renderPastState()}
            {renderFutureState()}
        </div>
    )
}

export default withRouter(connect()(ReviewOrder))
