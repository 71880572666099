import React from 'react'
import shortid from 'shortid'
import { connect } from 'react-redux'

import { upsertForm, clearForm } from '../../actions/form'
import { formStates } from '../../constants/helper-states'


class SHSForm extends React.Component {
    state = {
        formId: `f-${shortid.generate()}`
    }

    componentDidMount = () => {
        this.props.dispatch(upsertForm(this.state.formId, formStates.EMPTY))
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearForm(this.state.formId))
    }

    componentDidUpdate = (prevProps) => {
        const formStatus = this.props.forms.find(form => (form.get('id') === this.state.formId))
        const prevFormStatus = prevProps.forms.find(form => (form.get('id') === this.state.formId))
        // TODO: add check for previous form status...actually, do we need that?
        if (formStatus && formStatus.get('status') === formStates.SUCCESS && prevFormStatus.get('status') !== formStates.SUCCESS) {
            // TODO: look at props to figure out what we should do here, but default is to send body back
            if (this.props.resetForm === true) {
                this.props.dispatch(upsertForm(this.state.formId, formStates.EMPTY))
            }
            this.props.successFX(formStatus.get('body'))
        }
    }

    handleChange = (evt) => {
        const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value
        this.setState({ [evt.target.id]: value })
    }

    handleSubmit = (evt) => {
        evt.preventDefault()

        let validated = evt.target.checkValidity()
        evt.target.classList.remove('was-validated')
        if (!validated) {
            evt.target.classList.add('was-validated')
        } else {
            const { formId } = this.state
            const formDataObj = new FormData(evt.target)
            this.props.dispatch(upsertForm(formId, formStates.PROCESSING))
            if (this.props.useFormData) {
                // this is used when we need to send raw file data
                this.props.submitFX(formDataObj, formId)
            } else {
                let formData = {}
                formDataObj.forEach((val, key) => {
                    let inputField = document.querySelector(`.${this.state.formId} .${key}`)
                    let raw = inputField ? inputField.getAttribute('rawvalue') : null
                    if (raw !== '' && raw !== null) {
                        formData[key] = raw
                    } else {
                        formData[key] = val
                    }
                })
                this.props.submitFX(formData, formId)
            }
        }
    }

    handleCancel = (evt) => {
        evt.preventDefault()
        this.props.cancelAction()
    }

    render = () => {
        const formStatus = this.props.forms.find(stateForm => stateForm.get('id') === this.state.formId)
        const buttonLabel = this.props.buttonLabel || 'Submit'
        return (
            <form onSubmit={this.handleSubmit} className={`${this.state.formId} form ${this.props.extraClass || ''}`} noValidate>
                {this.props.children}
                {formStatus && formStatus.get('status') === 'ERROR' && formStatus.get('body') !== '' &&
                    <div className="alert alert-danger w-100">
                        {formStatus.get('body')}
                    </div>
                }
                <div className={`${this.props.formClass || 'd-flex w-100'}`}>
                    <button
                        type="submit"
                        className={`btn ${this.props.buttonClass || ''}`}
                        disabled={formStatus && (formStatus.get('status') === 'PROCESSING' || formStatus.get('status') === 'SUCCESS' || this.props.buttonDisabled) ? 'disabled' : null} >
                        {formStatus && formStatus.get('status') === 'SUCCESS' ? 'Redirecting...' : buttonLabel}
                    </button>
                    { this.props.showCancel &&
                        <button className={`btn cancel clickable ml-2 ${this.props.buttonClass || ''}`} onClick={this.handleCancel}>
                            { this.props.cancelButtonLabel || 'Cancel' }
                        </button>
                    }
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        forms: state.get('forms')
    }
}

export default connect(mapStateToProps)(SHSForm)
