import { CLEAR_FORM, UPSERT_FORM } from '../constants/actions'
import { formStates } from '../constants/helper-states'

export const upsertForm = (id, status = formStates.EMPTY, body = '') => {
    return {
        type: UPSERT_FORM,
        id,
        status,
        body
    }
}

export const clearForm = (formId) => {
    return {
        type: CLEAR_FORM,
        formId
    }
}

