import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import AuthBg from '../shared/auth-bg'
import { routes } from '../../constants/routes'

class Home extends React.Component {
    state = {}

    renderHelmet = () => {
        return (
            <Helmet>
                <title>Commercial Real Estate Analytics Dashboard</title>
                <meta name="description" content="Interactive presentations and analysis for CRE brokers." />
            </Helmet>
        )
    }

    render = () => {
        return (
            <>
                {this.renderHelmet()}
                <AuthBg />
                <div className="auth-container">
                    <img src="/assets/img/brand/visprop-logo-white.png" width="260" alt="VisProp Logo" title="VisProp Analytics" />
                    <p style={{ color: 'white', zIndex: '2' }} className="mt-5">
                        Welcome to dashboard.vispropanalytics.com.<br />
                        Interactive Commercial Real Estate Analytics
                    </p>
                    <Link style={{ color: 'white' }} className="mt-4" to={routes.login}>Proceed to Login</Link>
                </div>
            </>
        )
    }
}

export default Home
