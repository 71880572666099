import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import moment from 'moment'
import { routes } from '../constants/routes'

import { callLocalRedux } from '../actions/api'
import { handleFirebaseAuth, handleAnonymousFirebaseAuth, removeAuth } from '../actions/auth'
import { setActiveUserToken } from '../actions/ui'
import { fireauth } from '../firebase/firebase'

import Login from './auth/login'
import Logout from './auth/logout'
import Register from './auth/register'
import AcceptInvitation from './auth/accept-invitation'
import AuthRouter from './auth/router'
import PasswordHelp from './auth/password-help'
import PasswordReset from './auth/password-reset'
import AuthHandler from './auth/handler'

import CreateUser from './auth/create-user'
import CreateAccount from './auth/create-account'
import CreateSubscription from './auth/create-subscription'
import NeedSubscriptionSeat from './auth/need-subscription'

import Home from './pages/home'
import PrivacyPolicy from './pages/privacy-policy'
import TermsOfUse from './pages/terms-of-use'
import NotFound from './pages/not-found'
import ErrorPage from './pages/error-page'

import { AppRoute, AdminRoute } from './utils/app-route'

import Cookies from 'universal-cookie'
import DevTools from './utils/dev-tools'

import './utils/icons'

const AppIndex = lazy(() => import('./app/index'))
const AdminIndex = lazy(() => import('./admin/index'))
const SharedIndex = lazy(() => import('./shared/index'))

const cookies = new Cookies()

// attempting to set up pusher logic...
// import Pusher from 'pusher-js'

const ROUTER_PAGES = ['/login/', '/router/', '/register/', '/password-reset/']

class App extends React.Component {
    state = {
        hasAuthRouted: false
    }

    triggerAuthRouter = () => {
        const fromState = this.props.location.state && this.props.location.state.from.pathname
            ? this.props.location.state.from.pathname
            : null
        let nextLocation = {
            pathname: routes.router,
            state: { from: { pathname: fromState } }
        }
        if (this.props.location.pathname.indexOf('/shared/') !== -1) {
            nextLocation = {
                pathname: this.props.location.pathname
            }
        }
        this.props.history.push(nextLocation)
    }

    componentDidMount = () => {
        const queryParams = new Map(this.props.location.search.slice(1).split('&').map(kv => kv.split('=')))
        if (queryParams.has('type') && queryParams.get('type') === 'beta' && this.props.location.pathname === '/register/') {
            // we want to store beta registeration in local storage
            this.props.dispatch(callLocalRedux({
                id: 'REGISTER',
                registerType: 'BETA',
                registerTime: moment().add(1, 'hour').format(),
                _key: 'LOCAL_APP_STATE'
            }))
        }

        const { dispatch } = this.props
        // listens for login changes
        fireauth.onAuthStateChanged(firebaseUser => {
            if (firebaseUser) {
                if (firebaseUser.isAnonymous) {
                    dispatch(handleAnonymousFirebaseAuth(firebaseUser))
                } else {
                    dispatch(handleFirebaseAuth(firebaseUser))

                    const pathname = this.props.location.pathname
                    if (ROUTER_PAGES.indexOf(pathname) !== -1 ||
                        pathname.indexOf('/app/') !== -1 ||
                        pathname.indexOf('/admin/') !== -1 ||
                        pathname.indexOf('/invitation/') === 0
                    ) {
                        this.setState({ hasAuthRouted: true })
                        this.triggerAuthRouter()
                    }
                }
            } else {
                // TODO: this is problematic when there is a race condition to set temp auth?
                dispatch(removeAuth())
                this.setState({ hasAuthRouted: false })
            }
        })
        if (cookies.get('user-token-active') && !this.props.ui.get('userTokenActive')) {
            dispatch(setActiveUserToken())
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.state.hasAuthRouted && !this.props.auth.isEmpty()) {
            if (prevProps.location.pathname !== this.props.location.pathname) {
                const pathname = this.props.location.pathname
                if (ROUTER_PAGES.indexOf(pathname) !== -1 ||
                    pathname.indexOf('/app/') !== -1 ||
                    pathname.indexOf('/admin/') !== -1 ||
                    pathname.indexOf('/invitation/') === 0
                ) {
                    this.setState({ hasAuthRouted: true })
                    this.triggerAuthRouter()
                }
            }
        }
    }

    renderHelmet = () => {
        return (
            <Helmet>
                <title>VisProp Analytics Dashboard</title>
                <meta name="description" content="Your VisProp Analytics Dashboard." />
            </Helmet>
        )
    }

    render = () => {
        const { auth, ui, role } = this.props
        if (ui && ui.get('hasError') && window.location.pathname !== routes.errorPage) {
            return <Redirect to={routes.errorPage + `?page=${ui.get('page')}&code=${ui.get('code')}`} />
        }
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <AdminRoute auth={auth} role={role} path={routes.adminIndex} component={AdminIndex} />
                    <AppRoute auth={auth} path={routes.appIndex} component={AppIndex} />
                    <AppRoute auth={auth} path={routes.router} component={AuthRouter} />
                    <AppRoute auth={auth} path={routes.createUser} component={CreateUser} />
                    <AppRoute auth={auth} path={routes.createAccount} component={CreateAccount} />
                    <AppRoute auth={auth} path={routes.createSubscription} component={CreateSubscription} />
                    <AppRoute auth={auth} path={routes.needSubscriptionSeat} component={NeedSubscriptionSeat} />
                    <Route path={routes.sharedIndex} component={SharedIndex} />
                    <Route path={routes.authHandler} component={AuthHandler} />
                    <Route path={routes.login} component={Login} />
                    <Route path={routes.logout} component={Logout} />
                    <Route path={routes.register} component={Register} />
                    <Route path={routes.privacyPolicy} component={PrivacyPolicy} />
                    <Route path={routes.termsOfUse} component={TermsOfUse} />
                    <Route path={routes.invitation} component={AcceptInvitation} />
                    <Route path={routes.passwordHelp} component={PasswordHelp} />
                    <Route path={routes.passwordReset} component={PasswordReset} />
                    <Route path={routes.index} exact component={Home} />
                    <Route path={routes.errorPage} component={ErrorPage} />
                    <Route component={NotFound} />
                </Switch>
                <DevTools />
            </Suspense>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth'),
        ui: state.get('uiState'),
        role: state.get('role')
    }
}

export default withRouter(connect(mapStateToProps)(App))
