import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { routes, apiResourceEndpoint } from '../../constants/routes'
import { fetchApiDataIfNeeded, callApi } from '../../actions/api'

class AuthRouter extends React.Component {
    state = {
        readyToRoute: false,
        onboardStatus: '',
        stripeRequest: false
    }

    componentDidMount = () => {
        this.props.dispatch(fetchApiDataIfNeeded(
            apiResourceEndpoint('auth', 'LIST', null, 'check-user-account')
        ))
    }

    componentDidUpdate = (prevProps) => {
        const { account, auth, role, seat, user } = this.props
        if (auth && auth.has('authEmail')) {
            if (user && user.has('id')) {
                const userId = user.get('id')
                if (userId === -1) {
                    this.setState({ onboardStatus: 'CREATE_USER' })
                } else if (role && role.has('name')) {
                    if (user.has('accountId') && user.get('accountId') > 0) {
                        if (account && account.get('stripeCustomer')) {
                            if (account.hasIn(['_computed', 'subscriptionStatusValue']) && account.getIn(['_computed', 'subscriptionStatusValue']) === 'ACTIVE') {
                                // if they don't have a seat...
                                if (!seat || !seat.get('id')) {
                                    this.setState({ onboardStatus: 'SEAT_NEEDED' })
                                } else {
                                    this.setState({ readyToRoute: true })
                                }
                            } else {
                                this.setState({ onboardStatus: 'CREATE_SUBSCRIPTION' })
                            }
                        } else if (!this.state.stripeRequest) {
                            this.setState({ stripeRequest: true })
                            this.props.dispatch(callApi(apiResourceEndpoint('account', 'CREATE', null, 'stripe'), {}, -1))
                        }
                    } else {
                        this.setState({ onboardStatus: 'CREATE_ACCOUNT' })
                    }
                } else {
                    this.setState({ onboardStatus: 'CREATE_ACCOUNT' })
                }
            }
        }
    }

    render = () => {
        const { location } = this.props
        if (this.state.readyToRoute) {
            if (location.state && location.state.from.pathname) {
                return <Redirect to={location.state.from.pathname} />
            }
            return <Redirect to={routes.appIndex} />
        }
        switch (this.state.onboardStatus) {
        case 'CREATE_USER':
            return <Redirect to={routes.createUser} />
        case 'CREATE_ACCOUNT':
            return <Redirect to={routes.createAccount} />
        case 'CREATE_SUBSCRIPTION':
            return <Redirect to={routes.createSubscription} />
        case 'SEAT_NEEDED':
            return <Redirect to={routes.needSubscriptionSeat} />
        default:
            return <div>Router...</div>
        }
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.get('account'),
        auth: state.get('auth'),
        role: state.get('role'),
        seat: state.get('seat'),
        user: state.get('user')
    }
}

export default connect(mapStateToProps)(AuthRouter)
