import { fireauth } from '../firebase/firebase'
import moment from 'moment'
// import shortid from 'shortid'

import { API_METHODS, buildRoutePath } from '../constants/routes'
import { formStates } from '../constants/helper-states'
import { upsertForm } from './form'
import { setErrorCode } from './ui'

import isEqual from 'lodash/isEqual'

const API_URL = process.env.REACT_APP_API_URL

// const apiAction = (type, data) => {
//     return { type, data }
// }

export const cacheApiFetch = (path) => {
    return {
        type: 'SAVE_API_CALL',
        id: path
    }
}

const apiListAction = (data) => {
    if (data.length > 0 && '_key' in data[0]) {
        return { type: `SAVE_${data[0]._key}_LIST`, data }
    }
    return { type: null }
}

const apiSingleAction = (data) => {
    if (data && '_key' in data) {
        return { type: `SAVE_${data._key}`, data }
    }
    return { type: null }
}

export const apiRemoveAction = (data) => {
    if (data && '_key' in data) {
        return { type: `REMOVE_${data._key}`, data }
    }
    return { type: null }
}

export const clearSubscriptionSeats = () => {
    return {
        type: 'KILL_PARTIAL_SUBSCRIPTION_SEATS'
    }
}

export const clearSubscriptionSeatUsers = () => {
    return {
        type: 'KILL_PARTIAL_SUBSCRIPTION_SEAT_USERS'
    }
}

export const callLocalRedux = (data, formId = -1) => {
    return dispatch => {
        if (formId !== -1) {
            dispatch(upsertForm(formId, formStates.PROCESSING))
        }
        dispatch(manageApiData(data))
        if (formId !== -1) {
            dispatch(upsertForm(formId, formStates.SUCCESS, data.id))
        }
    }
}

const checkForObjEquality = (data, updateObj) => {
    if (!('_key' in data)) {
        return updateObj
    }
    let key = data._key
    let dataCopy = { ...data }
    let objCopy = { ...updateObj }
    delete dataCopy._related
    if (key in objCopy) {
        let match = false
        objCopy[key].forEach(keyData => {
            if (match) {
                return
            }
            // remove the related from the objs
            if (isEqual(keyData, dataCopy)) {
                // console.log('saving a match')
                match = true
                return
            }
        })
        if (!match) {
            objCopy[key].push(dataCopy)
        }
    } else {
        objCopy[key] = [dataCopy]
    }
    return objCopy
}

export const manageApiData = (data, updateObj = {}, depth = 0) => {
    let uObj = { ...updateObj }
    if (!data || (Array.isArray(data) && data.length === 0)) {
        if (depth === 0) {
            return dispatch => {
                return { type: null }
            }
        }
        return updateObj
    } else if (Array.isArray(data)) {
        data.forEach(item => {
            if (item && '_related' in item) {
                Object.keys(item._related).forEach(relation => {
                    let relatedObj = manageApiData(item._related[relation], uObj, depth + 1)
                    uObj = { ...relatedObj }
                })
            }
            let myObj = checkForObjEquality(item, uObj)
            uObj = { ...myObj }
        })
        if (depth > 0) {
            return uObj
        }
    } else {
        if ('_related' in data) {
            Object.keys(data._related).forEach(relation => {
                let relatedObj = manageApiData(data._related[relation], uObj, depth + 1)
                uObj = { ...relatedObj }
            })
        }

        let myObj = checkForObjEquality(data, uObj)
        uObj = { ...myObj }
        if (depth > 0) {
            return uObj
        }
    }
    if (depth === 0) {
        // console.log('what did we end up with?')
        // console.log(uObj)
        return dispatch => {
            Object.keys(uObj).forEach(updateKey => {
                // console.log('calling dispatch')
                if (uObj[updateKey].length === 1) {
                    dispatch(apiSingleAction(uObj[updateKey][0]))
                } else {
                    dispatch(apiListAction(uObj[updateKey]))
                }
            })
        }
    }
}

// the old version that just handles the raw response
// export const manageApiData = (data) => {
//     return dispatch => {
//         console.log('calling dispatch')
//         if (Array.isArray(data)) {
//             dispatch(apiListAction(data))
//             data.forEach(item => {
//                 if (item && '_related' in item) {
//                     Object.keys(item._related).forEach(relation => {
//                         dispatch(manageApiData(item._related[relation]))
//                     })
//                 }
//             })
//         } else {
//             dispatch(apiSingleAction(data))
//             if (data && '_related' in data) {
//                 Object.keys(data._related).forEach(relation => {
//                     dispatch(manageApiData(data._related[relation]))
//                 })
//             }
//         }
//     }
// }

export const fetchPublicApiData = (routeObj, data) => {
    return dispatch => {
        const apiRoute = buildRoutePath(routeObj.url, data)
        let fetchParams = {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            method: routeObj.method
        }
        const apiUrl = `${API_URL}${apiRoute}`
        dispatch(requestFetch(apiUrl))
        return fetch(apiUrl, fetchParams)
            .then(response => {
                return Promise.all([response, response.json()])
            })
            .then(([response, result]) => {
                if (response.ok) {
                    dispatch(receiveFetch(apiUrl))
                    dispatch(manageApiData(result.data))
                } else {
                    dispatch(setErrorCode(result.code))
                    throw new Error(`${result.code}: ${result.errors}`)
                }
            })
            .catch(err => {
                console.log('error in public fetch...')
                dispatch(fetchError(apiUrl, err.message))
            })
    }
}

// this is used for all form submissions now
export const callApi = (routeObj, data, formId) => {
    return dispatch => {
        dispatch(upsertForm(formId, formStates.PROCESSING))
        return fireauth.currentUser.getIdToken()
            .then(token => {
                let query = ''
                let fetchParams = {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    method: routeObj.method
                }
                if (routeObj.method === API_METHODS.GET) {
                    query = Object.keys(data).map(key => {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                    }).join('&')
                    if (query !== '') {
                        query = `?${query}`
                    }
                } else if (routeObj.method === API_METHODS.POST) {
                    fetchParams.body = JSON.stringify(data)
                }
                let apiRoute = buildRoutePath(routeObj.url, routeObj.params)
                const apiUrl = `${API_URL}${apiRoute}${query}`
                return fetch(apiUrl, fetchParams)
            })
            .then(response => {
                return Promise.all([response, response.json()])
            })
            .then(([response, result]) => {
                if (response.ok) {
                    if (routeObj.method === API_METHODS.DELETE) {
                        dispatch(apiRemoveAction(result.data))
                    } else {
                        dispatch(manageApiData(result.data))
                    }
                    dispatch(upsertForm(formId, formStates.SUCCESS, result.data.id))
                } else {
                    if (result.code) {
                        throw new Error(`${result.code}: ${result.errors}`)
                    }
                    // this should handle the responses from validation endpoints
                    let msgs = []
                    Object.keys(result).forEach(key => {
                        msgs.push(result[key].join(', '))
                    })
                    throw new Error(msgs.join(', '))
                }
            })
            .catch(err => {
                // TODO: need to log this?
                dispatch(upsertForm(formId, formStates.ERROR, err.message))
            })
    }
}

export const fetchConfigData = (routeUrl, params = {}) => {
    return fireauth.currentUser.getIdToken()
        .then(token => {
            let fetchParams = {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                method: 'GET'
            }
            let query = ''
            query = Object.keys(params).map(key => {
                if (Array.isArray(params[key])) {
                    return params[key].map(ad => {
                        return encodeURIComponent(key) + '[]=' + encodeURIComponent(ad)
                    }).join('&')
                }
                return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&')
            if (query !== '') {
                query = `?${query}`
            }
            const apiUrl = `${API_URL}${routeUrl}${query}`
            return fetch(apiUrl, fetchParams)
        })
        .then(response => {
            return Promise.all([response, response.json()])
        })
        .then(([response, result]) => {
            if (response.ok) {
                return result.data
            }
            return Promise.resolve()
        })
        .catch(err => {
            // TODO: need to log this?
            // dispatch(fetchError(routeUrl, err.message))
            console.log('blah')
        })
}

export const callFileApi = (routeObj, data, formId) => {
    return dispatch => {
        dispatch(upsertForm(formId, formStates.PROCESSING))
        console.log(data)
        return fireauth.currentUser.getIdToken()
            .then(token => {
                const fetchParams = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    method: 'POST',
                    body: data
                }
                let apiRoute = buildRoutePath(routeObj.url, routeObj.params)
                const apiUrl = `${API_URL}${apiRoute}`
                return fetch(apiUrl, fetchParams)
            })
            .then(response => {
                return Promise.all([response, response.json()])
            })
            .then(([response, result]) => {
                if (response.ok) {
                    dispatch(manageApiData(result.data))
                    dispatch(upsertForm(formId, formStates.SUCCESS, result.data.id))
                } else {
                    throw new Error(`${result.code}: ${result.errors}`)
                }
            })
            .catch(err => {
                console.log('error in file upload')
                dispatch(upsertForm(formId, formStates.ERROR, err.message))
            })
    }
}

export const fetchFileViaApi = (routeObj, params = {}, filename) => {
    return dispatch => {
        const routeUrl = buildRoutePath(routeObj.url, params)
        return fireauth.currentUser.getIdToken()
            .then(token => {
                let fetchParams = {
                    headers: { Authorization: `Bearer ${token}` },
                    method: 'GET'
                }
                const apiUrl = `${API_URL}${routeUrl}`
                return fetch(apiUrl, fetchParams)
            })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
                link.parentNode.removeChild(link)
                dispatch({ type: 'FILE_DOWNLOADED' })
            })
            .catch(err => {
                console.log('something went wrong... ', err)
            })
    }
}

export const searchApi = (routeObj, data = {}) => {
    return fireauth.currentUser.getIdToken()
        .then(token => {
            let query = ''
            let fetchParams = {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                method: 'GET'
            }
            query = Object.keys(data).map(key => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&')
            if (query !== '') {
                query = `?${query}`
            }
            let apiRoute = buildRoutePath(routeObj.url, routeObj.params)
            const apiUrl = `${API_URL}${apiRoute}${query}`
            return fetch(apiUrl, fetchParams)
        })
        .then(response => {
            return Promise.all([response, response.json()])
        })
        .then(([response, result]) => {
            if (response.ok) {
                return result.data
            }
            throw new Error(`${result.code}: ${result.errors}`)
        })
        .catch(err => {
            // TODO: need to log this?
            console.log('error in search form submission?')
            console.log(err.message)
        })
}

const requestFetch = (routeUrl, params) => {
    return {
        type: 'SAVE_API_CALL',
        data: {
            url: routeUrl,
            isFetching: true,
            hasError: false,
            params
        }
    }
}

const receiveFetch = (routeUrl) => {
    return {
        type: 'SAVE_API_CALL',
        data: {
            url: routeUrl,
            isFetching: false,
            hasError: false,
            lastFetch: moment().format()
        }
    }
}

const fetchError = (routeUrl, error) => {
    return {
        type: 'SAVE_API_CALL',
        data: {
            url: routeUrl,
            isFetching: false,
            hasError: true,
            error
        }
    }
}

export const clearApiFetch = (routeUrl) => {
    return {
        type: 'CLEAR_API_CALL',
        data: {
            url: routeUrl
        }
    }
}

// TODO: Need to be able to handle query paramters, like for pagination, etc.
const fetchApiData = (routeUrl, params) => {
    return dispatch => {
        // dispatch the isLoading....
        let fetchUrl = ''
        return fireauth.currentUser.getIdToken()
            .then(token => {
                let query = ''
                let fetchParams = {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    method: 'GET'
                }
                query = Object.keys(params).map(key => {
                    if (Array.isArray(params[key])) {
                        return params[key].map(ad => {
                            return encodeURIComponent(key) + '[]=' + encodeURIComponent(ad)
                        }).join('&')
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                }).join('&')
                if (query !== '') {
                    query = `?${query}`
                }
                fetchUrl = `${API_URL}${routeUrl}${query}`
                dispatch(requestFetch(fetchUrl, params))
                return fetch(fetchUrl, fetchParams)
            })
            .then(response => {
                return Promise.all([response, response.json()])
            })
            .then(([response, result]) => {
                if (response.ok) {
                    dispatch(receiveFetch(fetchUrl))
                    dispatch(manageApiData(result.data))
                } else {
                    throw new Error(`${result.code}: ${result.errors}`)
                }
                return Promise.resolve()
            })
            .catch(err => {
                // TODO: need to log this?
                dispatch(fetchError(fetchUrl, err.message))
            })
    }
}

const shouldFetchApiData = (apiCaches, routeUrl, params) => {
    const cachedRoute = apiCaches.find(cache => cache.get('url') === routeUrl && cache.get('params') === params)
    if (!cachedRoute) {
        return true
    } else if (cachedRoute.get('isFetching')) {
        return false
    }
    const lastFetch = moment(cachedRoute.get('lastFetch'))
    const lapsedTime = moment().diff(lastFetch, 's', true)
    if (lapsedTime < 5) {
        return false
    }
    return true
}

export const fetchApiDataIfNeeded = (routeObj, params = {}, force = false) => {
    const routeUrl = buildRoutePath(routeObj.url, params)
    return (dispatch, getState) => {
        if (shouldFetchApiData(getState().get('apiCaches'), routeUrl, params) || force) {
            dispatch(fetchApiData(routeUrl, params))
        }
    }
}

export const deleteViaApi = (routeObj, params = {}) => {
    const routeUrl = buildRoutePath(routeObj.url, params)
    const deleteUrl = `/delete${routeUrl}`
    return dispatch => {
        dispatch(requestFetch(deleteUrl))
        return fireauth.currentUser.getIdToken()
            .then(token => {
                let fetchParams = {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    method: 'DELETE'
                }
                const apiUrl = `${API_URL}${routeUrl}`
                return fetch(apiUrl, fetchParams)
            })
            .then(response => {
                return Promise.all([response, response.json()])
            })
            .then(([response, result]) => {
                if (response.ok) {
                    dispatch(receiveFetch(deleteUrl))
                    dispatch(apiRemoveAction(result.data))
                } else {
                    throw new Error(`${result.code}: ${result.errors}`)
                }
                return Promise.resolve()
            })
            .catch(err => {
                dispatch(fetchError(deleteUrl, err.message))
            })
    }
}


// export const fakeApiCall = (routeObj, data, statusId) => {
//     if (!data.id) {
//         data.id = shortid.generate()
//     }
//     return dispatch => {
//         dispatch(upsertForm(statusId, formStates.SUCCESS, data.id))
//         dispatch(manageApiData(data))
//     }
// }

export const fakeReduxCall = (routeObj, data) => {
    return { type: routeObj.action, data }
    // return dispatch => {
    //     // dispatch(manageApiData(data))
    //     dispatch(apiAction(routeObj.action, data))
    // }
}
