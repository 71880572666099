import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { routes } from '../../constants/routes'

import { signOut } from '../../actions/auth'

class PublicNav extends React.Component {
    state = {

    }

    renderAuthLinks = () => {
        if (this.props.auth && this.props.auth.has('authEmail')) {
            return (
                <div className="col text-right">
                    { this.props.auth.has('authEmail') &&
                        <span>{this.props.auth.get('authEmail')}</span>
                    }
                    <span className="pl-2" onClick={() => this.props.dispatch(signOut())}>
                        Log Out
                    </span>
                </div>
            )
        }
        return (
            <div className="col text-right">
                <Link to={routes.login}>Login</Link>
                <Link to={routes.register} className="pl-2">Register</Link>
            </div>
        )
    }

    render = () => {
        return (
            <div className="row p-2">
                <div className="col">
                    <Link to={routes.index}>Home</Link>
                </div>
                { this.renderAuthLinks() }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.get('auth')
    }
}

export default connect(mapStateToProps)(PublicNav)
