import immutable from 'immutable'

import * as helpers from './helpers'
import { KILL_STATE } from '../constants/actions'

const INITIAL_STATE = immutable.fromJS([])

const siteScenarioReducer = (state = INITIAL_STATE, action) => {
    let objectIndex = -1
    // let newState = state
    switch (action.type) {
    case 'SAVE_SITE_TO_SCENARIO_STATE':
        objectIndex = helpers.getObjectIndex(state, action.data.id)
        if (objectIndex === -1) {
            return helpers.addObject(state, action.data)
        }
        return helpers.updateObject(state, action.data, objectIndex)
    case 'REMOVE_SITE_FROM_SCENARIO_STATE':
        return state.filter(field => {
            return !(action.data.id === field.get('id') && action.data.scenarioTempId === field.get('scenarioTempId'))
        })
    case 'REMOVE_ALL_SITES_FROM_SCENARIO_STATE':
        return state.filter(field => {
            return action.data.scenarioTempId !== field.get('scenarioTempId')
        })
    case KILL_STATE:
        return INITIAL_STATE
    default:
        return state
    }
}

export default siteScenarioReducer
