import { fromJS } from 'immutable'

export function getObjectIndex(state, value, field = 'id') {
    return state.findIndex((object)=>{
        return object.get(field) === value
    })
}

export function addObject(state, model) {
    return state.update((objects)=>{
        let object = new fromJS(model)
        return objects.push(object)
    })
}

export function updateObject(state, model, objectIndex) {
    return state.update(objectIndex, (object) => {
        return object.merge(new fromJS(model))
    })
}
