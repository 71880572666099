export const setErrorCode = (code) => {
    return {
        type: 'SET_ERROR_CODE',
        hasError: true,
        code
    }
}

export const clearErrorCode = () => {
    return {
        type: 'CLEAR_ERROR_CODE'
    }
}

export const setViewMode = (mode = 'default') => {
    return {
        type: 'SET_VIEW_MODE',
        mode
    }
}

export const setSidebarState = (show = true) => {
    return {
        type: 'SIDEBAR_STATE',
        show
    }
}

export const setFilterState = (filterData) => {
    return {
        type: 'SET_FILTER_STATE',
        data: filterData
    }
}

export const clearFilterState = (filterData) => {
    return {
        type: 'CLEAR_FILTER_STATE',
        data: filterData
    }
}

export const setSortState = (sortData) => {
    return {
        type: 'SET_SORT_STATE',
        data: sortData
    }
}

export const clearSortState = (sortData) => {
    return {
        type: 'CLEAR_SORT_STATE',
        data: sortData
    }
}

export const setDisplayState = (displayData) => {
    return {
        type: 'SET_DISPLAY_STATE',
        data: displayData
    }
}

export const clearDisplayState = (displayData) => {
    return {
        type: 'CLEAR_DISPLAY_STATE',
        data: displayData
    }
}

export const setActiveUserToken = () => {
    return {
        type: 'SET_ACTIVE_USER_TOKEN'
    }
}
