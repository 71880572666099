import { library } from '@fortawesome/fontawesome-svg-core'

import { faCcVisa, faCcAmex, faCcMastercard, faCcDiscover } from '@fortawesome/free-brands-svg-icons'

import {
    faPencilAlt, faCheckCircle, faCircle, faCheck, faTimes, faCloudDownloadAlt,
    faFileDownload, faSpinner, faReply, faEllipsisV,
    faChevronCircleLeft, faChevronCircleRight, faChevronDown, faChevronRight, faUsers, faSitemap, faTools, faPowerOff, faUsersCog,
    faCog, faUserCircle, faThLarge, faList, faPlus, faSearch, faSquare, faEye, faEyeSlash, faExchangeAlt, faComment, faTrashAlt, faBrain, faClipboard, faShare, faPlusCircle, faSort, faSlidersH,
    faArrowDown, faArrowUp, faArrowLeft, faArrowRight, faPaperPlane, faMinus, faThumbsUp, faThumbsDown, faSave, faBookmark, faCommentAlt, faCopy, faCaretDown, faFolderOpen, faSync,
    faToggleOn, faToggleOff, faUserPlus, faEdit, faMinusCircle, faCreditCard, faPen, faLightbulb, faImages, faChartPie,
    faSeedling, faCarrot, faFrog, faVolleyballBall, faTractor, faWind, faMountain, faFire, faUmbrella, faCampground, faTree, faHammer, faWrench, faTint,
    faBone, faRainbow, faIceCream, faFish, faLeaf, faCrow, faSpider, faGlasses, faTrophy, faAnchor, faDharmachakra, faGuitar, faRocket, faSnowflake, faSun,
    faChalkboardTeacher, faPrint, faCheckSquare, faCogs, faSignOutAlt, faHome, faBuilding, faBriefcase, faAddressBook, faDotCircle, faQuestionCircle, faMapMarked
} from '@fortawesome/free-solid-svg-icons'

import {
    faCircle as farCircle, faBookmark as farBookmark, faSadTear
} from '@fortawesome/free-regular-svg-icons'

library.add(
    faPencilAlt, faCheckCircle, faCircle, faCheck, faTimes, faCloudDownloadAlt,
    faFileDownload, faSpinner, faReply, faEllipsisV,
    faChevronCircleLeft, faChevronCircleRight, faChevronDown, faChevronRight, faUsers, faSitemap, faTools, faPowerOff, faUsersCog,
    faCog, faUserCircle, faThLarge, faList, faPlus, faSearch, faSquare, faEye, faEyeSlash, faExchangeAlt, faComment, faTrashAlt, faBrain, faClipboard, faShare, faPlusCircle, faSort, faSlidersH,
    faArrowDown, faArrowUp, faArrowLeft, faArrowRight, faPaperPlane, faMinus, faThumbsUp, faThumbsDown, faSave, faBookmark, farBookmark, faCommentAlt, faCopy,
    faToggleOn, faToggleOff, faUserPlus, faEdit, faMinusCircle, faCreditCard, faPen, faLightbulb, faImages, faChartPie,
    faSeedling, faCarrot, faFrog, faVolleyballBall, faTractor, faWind, faMountain, faFire, faUmbrella, faCampground, faTree, faHammer, faWrench, faTint,
    faBone, faRainbow, faIceCream, faFish, faLeaf, faCrow, faSpider, faGlasses, faTrophy, faAnchor, faDharmachakra, faGuitar, faRocket, faSnowflake, faSun,
    faChalkboardTeacher, faPrint, faCheckSquare, faCogs, faSignOutAlt, faHome, faBuilding, faBriefcase, faAddressBook, faDotCircle, faQuestionCircle, faMapMarked,
    faSadTear,
    faCcVisa, faCcAmex, faCcMastercard, faCcDiscover, farCircle, faCaretDown, faFolderOpen, faSync
)
