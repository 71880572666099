import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import AuthBg from '../shared/auth-bg'
import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

import { signInWithEmailAndPass } from '../../actions/auth'
import { routes } from '../../constants/routes'

class Login extends React.Component {
    state = {}

    renderHelmet = () => {
        return (
            <Helmet>
                <title>Login for VisProp Analytics Dashboard</title>
                <meta name="description" content="Login to your VisProp Anlytics dashboard. Access your project, reports, and real estate analysis." />
            </Helmet>
        )
    }

    successFX = () => {
        // nothing for now
    }

    submitFX = (formData, formId) => {
        this.props.dispatch(signInWithEmailAndPass(formData.email, formData.password, formId))
    }

    render = () => {
        return (
            <>
                {this.renderHelmet()}
                <AuthBg />
                <div className="auth-container">
                    <Link to={routes.index}>
                        <img src="/assets/img/brand/visprop-logo-white.png" width="260" alt="VisProp Logo" title="VisProp Logo" />
                    </Link>
                    <div className="content col-11 col-sm-4">
                        <h1>Login to VisProp</h1>
                        <p>Access your VisProp Analytics account by logging in with the form below.</p>
                        <SHSForm submitFX={this.submitFX} successFX={this.successFX} buttonLabel="Login" buttonClass="black center">
                            <InputElement
                                type="email"
                                default=""
                                id="email"
                                label="Email Address"
                                placeholder="user@example.com"
                                errorMessage="Please provide a valid email address"
                                extraClass="flex-100"
                                required />
                            <InputElement
                                type="password"
                                default=""
                                id="password"
                                label="Password"
                                placeholder="A secret you only know..."
                                errorMessage="Password is required to be a minimum of 6 characters"
                                required />
                        </SHSForm>
                    </div>
                    <div className="links col-sm-4">
                        <p>
                            <Link to={routes.passwordHelp}>Forgot password or need help logging in?</Link>
                        </p>
                        <p>
                            Don't have an account? <Link to={routes.register}>Sign Up.</Link>
                        </p>
                    </div>
                </div>
            </>
        )
    }
}

export default connect()(Login)
