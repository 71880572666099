import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'

class NotFound extends React.Component {
    render = () => {
        return (
            <div className="container">
                <h1>Not found.</h1>
                <p>Could not find what you were looking for.</p>
                <p>
                    <Link to={routes.index}>Go to Home page</Link>
                </p>
            </div>
        )
    }
}

export default NotFound
