import React from 'react'

class AuthBg extends React.Component {
    render = () => {
        return (
            <div id="auth-bg" style={{ backgroundImage: `url(/assets/img/layout/saybrook-auth-bg.jpg)` }} />
        )
    }
}

export default AuthBg
